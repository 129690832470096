import React, { Component } from 'react'
import axios from 'axios'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, Table, Upload, Alert, Select, notification, Steps, Descriptions} from 'antd';
import { SettingOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import Parse from 'parse'
import _ from 'lodash'
import {isMobile} from 'react-device-detect';
import {FILTER_FLOAT, NUMBER_WITH_COMMAS} from '../../utils'
import PaymongoLogo from '../../assets/img/powered_by_paymongo.png'
import CreditCardLogo from '../../assets/img/payment_logo/cards_logo.png'
import GcashLogo from '../../assets/img/payment_logo/gcash.png'
import MayaLogo from '../../assets/img/payment_logo/maya.png'

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";
import { E_PAYMENT } from '../../redux/actions/ActionTypes'

const {Meta} = Card


class PaymentForm extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        steps_arr : null,
        current_step : 0,
        currencies : null,
        currency_selection : null,

        selected_currency : null,
        amount : 0,
        intent_obj : null,
        selected_payment_mode : null,

        email_address : null,
        contact_number : null,
        name : null        
    }

    componentDidMount = () => {
        console.log(this.props)
        this.setState({
            steps_arr : [
                {
                    title: 'Payment Info'
                },
                {
                    title: 'Payment Option'
                },
                {
                    title: 'Billing Details'
                },
                {
                    title: 'Summary'
                },
                {
                    title: 'Payment',
                },
            ]
        })

        this.getCurrencies()
    }

    getCurrencies = () => {
        Parse.Cloud.run('getEpayCurrency').then(async result => {
            if(result.success){

                let prep_selection = []
                await Promise.all(
                    result.data.map(c => {
                        prep_selection.unshift({
                            value : c.id,
                            label : c.currency_code
                        })
                    })
                )

                console.log(prep_selection)


                this.setState({currencies : result.data, currency_selection : prep_selection})
            }else{
                notification['error']({
                    message: 'Error',
                    description: result.message
                });
            }
        }).catch(e => {
            notification['error']({
                message: 'Error',
                description: e.message
            });
        })
    }

    // FUNCTION ARE
    onSelectCurrency = (val) => {
        let {currencies} = this.state
        let selected_obj = _.find(currencies, (o) => o.id === val)
        
        console.log(selected_obj)

        this.setState({selected_currency : selected_obj})
    }

    nextHandler = (step) => {
        let {actions, type, description} = this.props
        let { name, email_address, contact_number, selected_payment_mode, amount, selected_currency} = this.state
        if(step === 1){
            // Create Payment Intent
            Parse.Cloud.run('createPaymentIntent', {
                amount : amount,
                description : description,
                descriptor : type,
                currency : selected_currency.currency_code 
            }).then(result => {
                if(result.success){
                    actions.setValue({intent : result.data}, E_PAYMENT)
                    console.log(result)
                }else{
                    notification['error']({
                        message: 'Error',
                        description: result.message
                    });
                }
            }).catch(e => {
                console.log(e)
                notification['error']({
                    message: 'Error',
                    description: e.message
                });
            })
        }

        if(step === 4){
            Parse.Cloud.run('createPaymentMethod', {
                mop : selected_payment_mode,
                name : name,
                email : email_address,
                phone : contact_number,
                card_number : '',
                cvv : ''
            }).then(result => {
                actions.setValue({payment_method : result.data}, E_PAYMENT)
                console.log('createPaymentMethod', result)

                // ATTACH PAYMENT INTENT
            })
        }

        this.setState({current_step : step})
    }


    // CONTENT AREA
    paymentInformationContent = () => {
        let {currency_selection, selected_currency, amount} = this.state
        return (
            <div style={{display : 'flex', alignItems : 'center', flexDirection : 'column'}}>
                {/* <Row> */}
                {
                    isMobile ?
                        null  
                    : <h3>Payment Information</h3>
                }
                {/* <h4>Payment Information</h4> */}
                
                <br/>
                <Space direction='vertical' style={{alignItems : 'center', marginBottom : 30}}>
                    <strong>Your Currency</strong>
                    <Select
                        size='large'
                        showSearch
                        value={selected_currency ? selected_currency.id : null}
                        style={{ width: '100%'}}
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={currency_selection}
                        onChange={(e) => {this.onSelectCurrency(e)}}
                    />
                </Space>
                <Space direction='vertical' style={{alignItems : 'center'}}>
                    <strong>Top-up Amount</strong>
                    <Input 
                        disabled={!selected_currency}
                        value={amount}
                        size='large' 
                        addonBefore={selected_currency ? selected_currency.currency_code : 'PHP'}
                        onChange={(e) => this.setState({amount : FILTER_FLOAT(e.target.value)})}
                    />
                    <small>Conversion in PHP : {selected_currency && selected_currency.exchange_rates ? NUMBER_WITH_COMMAS((parseFloat(amount) * selected_currency.exchange_rates.exchange_rates.PHP).toFixed(2)) : 'N/A'}</small>
                </Space>
                {/* </Row> */}
            </div>
        )
    }

    paymentOptionContent = () => {
        let {selected_payment_mode} = this.state
        return (
            <div>
                <div style={{alignItems : 'center', display : 'flex', flexDirection : 'column', justifyContent : 'center'}}>
                <h4>Select Payment Mode</h4>
                    <Row gutter={10} style={{justifyContent : 'center', alignItems : 'center'}}>
                        <Col style={{marginBottom : 10}}>
                            <Card
                                id={selected_payment_mode == "cc_card" ? "payment_selected" : ""}
                                onClick={() => {this.setState({selected_payment_mode : 'cc_card'})}}
                                hoverable
                                style={{width : 200}}
                                cover={<img alt="Credit or Debit Card" src={CreditCardLogo} style={{padding : 20, height : 150, width : '130px !important'}} />}
                            >
                                <Meta title="Credit/Debit Card" />
                            </Card>
                        </Col>
                        <Col style={{marginBottom : 10}}>
                            <Card
                                id={selected_payment_mode == "gcash" ? "payment_selected" : ""}
                                onClick={() => {this.setState({selected_payment_mode : 'gcash'})}}
                                hoverable
                                style={{width : 200}}
                                cover={<img alt="Credit or Debit Card" src={GcashLogo} style={{padding : 20, height : 150, width : '130px !important'}} />}
                            >
                                <Meta title="GCash" />
                            </Card>
                        </Col>
                        <Col style={{marginBottom : 10}}>
                            <Card
                                id={selected_payment_mode == "maya" ? "payment_selected" : ""}
                                onClick={() => {this.setState({selected_payment_mode : 'maya'})}}
                                hoverable
                                style={{width : 200}}
                                cover={<img alt="Credit or Debit Card" src={MayaLogo} style={{padding : 20, height : 150, width : '130px !important'}}  />}
                            >
                                <Meta title="Maya" />
                            </Card>
                        </Col>
                        
                    </Row>
                </div>
            </div>
        )
    }

    billingDetailsContent = () => {

        return (
            <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center'}}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <h4>Billing Details</h4>
                    <Row style={{justifyContent : 'center'}} gutter={10}>
                        <Col
                            xs={24} sm={24} md={12} lg={12}
                        >
                            <Input 
                                size='large'
                                placeholder='Email Address'
                                onChange={(e) => this.setState({email_address : e.target.value})}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Input 
                                size='large'
                                placeholder='Contact #'
                                onChange={(e) => this.setState({contact_number : e.target.value})}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} style={{marginTop : 20}}>
                            <Input 
                                size='large'
                                placeholder='Name '
                                onChange={(e) => this.setState({name : e.target.value})}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
        )

    }

    summaryContent = () => {
        let {amount, selected_currency, selected_payment_mode, name, email_address, contact_number } = this.state
        return (
            <Space direction='vertical' style = {{width : '100%', padding : 10}}>
                <Descriptions title="Payment Information" bordered>
                    <Descriptions.Item label="Description" span={3}>{this.props.description}</Descriptions.Item>
                    <Descriptions.Item label="Amount">{selected_currency ? selected_currency.currency_code : 'PHP'} {amount}</Descriptions.Item>
                    <Descriptions.Item label="Payment Mode">{selected_payment_mode ? selected_payment_mode : ''}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Billing Details" bordered>
                    <Descriptions.Item label="Name" span={3}>{name}</Descriptions.Item>
                    <Descriptions.Item label="E-mail">{email_address}</Descriptions.Item>
                    <Descriptions.Item label="Contact Number">{contact_number}</Descriptions.Item>
                </Descriptions>
            </Space>
        )
    }

    render() { 
        let {current_step, steps_arr} = this.state
        let {EPayment} = this.props
        return (
            <div style={{marginTop : 30}}>
                <Row>
                    <Col 
                        xs={{span : 22, offset : 1}} sm={{span : 22, offset : 1}} 
                        md={{span: 18, offset : 3}}
                        lg={{span: 18, offset : 3}}
                        xl={{span: 18, offset : 3}}
                    >
                        <Card
                            title={
                                isMobile ?
                                    <h4>
                                        {steps_arr ? steps_arr[current_step].title : ''}
                                    </h4>
                                :
                                    <Steps
                                        current={current_step}
                                        progressDot
                                        style={{marginTop : 30, marginBottom : 20}}
                                        items={steps_arr}
                                    />
                            }
                            actions={[
                                
                                <>
                                    <img src={PaymongoLogo} style={{width : isMobile ? 120: 150, height : '0 auto'}}/><br/>
                                    <small style={{fontSize : '10px'}}>{EPayment && EPayment.intent ? EPayment.intent.data.id : '' }</small>
                                    <small style={{fontSize : '10px'}}>{EPayment && EPayment.payment_method ? EPayment.payment_method.data.id : '' }</small>
                                </>,
                                <strong onClick={() => {this.setState({current_step : current_step - 1})}}> {`< Back`}</strong>,
                                <strong onClick={() => { this.nextHandler(current_step + 1)}}>{`Next >`}</strong>,
                                ]}
                        >
                            <div style={{minHeight : 350}}>
                                {
                                    current_step === 0 ?
                                        this.paymentInformationContent()
                                    : current_step === 1 ?
                                        this.paymentOptionContent()
                                    : current_step === 2 ?
                                        this.billingDetailsContent()
                                    : current_step === 3 ? 
                                        this.summaryContent()
                                    : null
                                }
                                
                            </div>
                            

                        </Card>
                        
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        EPayment : state.EPayment
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
// export default PaymentForm;