
import { TOKEN, GENERATE_TOKEN } from "../actions/ActionTypes";
import { IS_UNDEFINED } from "../../utils";
import moment from "moment"


const initialState = {
  resetState: false,
  token : '',
  verification_sent : false,
  timer : 0,
  verified_success : false,
  verified_failed : false
};




export default (state = initialState, action) => {
  switch (action.type) {
    case TOKEN:
      if (!IS_UNDEFINED(action.payload.resetState) && action.payload.resetState) {
        action.payload = {...initialState};
      }
      
      return Object.assign({}, state, action.payload);

    case GENERATE_TOKEN :

      // let string = `${mobile_number}-${moment().unix().toString()}`
      // const cryptr = new Cryptr('dlwlrma00', { pbkdf2Iterations: 10000, saltLength: 10 });

      // const encryptedString = cryptr.encrypt(string);
      
      // let {mobile_number} =  action.payload
      
      // return Object.assign({}, state, {token : encryptedString});

    default: return state
  }
}
