import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, Table, Upload, Alert, Select, notification} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PageLayout from '../../page_layout'
import { Parallax } from 'react-scroll-parallax';
import moment from 'moment';
import _ from 'lodash'
import Parse from 'parse';
import {isMobile} from 'react-device-detect';
import EmptyImage from '../../assets/img/empty_box.png'
import BeforeUnloadComponent from 'react-beforeunload-component';
import { FILTER_FLOAT} from '../../utils'
import { hot } from 'react-hot-loader/root';

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";

import { INFO_DISPLAY } from '../../redux/actions/ActionTypes'
import {s3Client, uploadFileToObjectStorage, deleteFileFromObjectStorage} from '../../controller/bucket_controller'

const {TextArea} = Input

const init_state = {
    is_table_loading : true,
    parcel_source : null,
    parcel_couriers : [],
    payment_options : [],
    courier_selection : [],
    payment_selection : [],

    dropped_items : [],
    
    selected_payment_option : null,
    selected_courier : null,
    to_pay : 0,
    tracking_number : '',
    item_specification : '',
    ref_link : '',
    imageUrl : null,
    image_url_file : null,
    base64Img : null,

}

class ShopDropShip extends Component {
    constructor(props) {
        super(props);
    }
    state = init_state
 
    componentDidMount = async () => {
        await this.getSelections()
        await this.getDroppedParcels()
    }

    getSelections = async () => {
        await Parse.Cloud.run('getSDSCouriers').then(async result => {
            // console.log(result)
            if(result.success){
                let grouped_data = _.groupBy(result.data, o => o.category)

                let prep_selection = []
                for (const key in grouped_data) {

                    let prep_options = []

                        await Promise.all(grouped_data[key].map((v) => {
                            prep_options.push({
                                label : v.name,
                                value : v.id,
                                disabled : !v.enabled
                            })
                        }))
    
                        prep_selection.unshift({
                            label: key,
                            options: prep_options
                        })

                }

                this.setState({
                    parcel_couriers : result.data,
                    courier_selection : prep_selection
                })
            }
        })
        
        await Parse.Cloud.run('getSDSPaymentOption').then(async result => {
            if(result.success){
                let prep_data = []
                await Promise.all(
                    result.data.map(item => {
                        prep_data.unshift(
                            { value: item.id, label: item.name }
                        )
                    })
                )
                this.setState({payment_options : result.data, payment_selection : prep_data})
                
            }
        })
    }

    getDroppedParcels = async() => {
        await Parse.Cloud.run('getDroppedParcels').then(result => {
            console.log(result)
            if(result.success){
                this.setState({dropped_items : result.data, is_table_loading : false})
            }
        })
    }

    onSelectPaymentOption = async (val) => {

        let {payment_options} = this.state
        let selected_obj = _.find(payment_options, (o) => o.id === val)
        console.log(selected_obj)

        this.setState({selected_payment_option : selected_obj})
        
    }

    onSelectCourier = async (val) => {
        let {parcel_couriers} = this.state
        let selected_obj = _.find(parcel_couriers, (o) => o.id === val)

        this.setState({selected_courier : selected_obj})
        
    }

    handleChangeUpload = async (info) => {
        let temp_url = URL.createObjectURL(info.file.originFileObj)
        
        await this.getBase64(info.file.originFileObj, (base64) => {
            this.setState({imageUrl : temp_url, base64Img : base64})
        });
    };

    handleChangeFile = async (e) => {
        let {image_url_file} = this.state

        if(image_url_file){
            await deleteFileFromObjectStorage(image_url_file).then(async () => {
                await this.setState({image_url_file : [e.file]})
            })
        }
    }

    handleCancelFile = async (e, url) => {
        await deleteFileFromObjectStorage(url)
        await this.setState({image_url_file : null})
        
         e.preventDefault()
    }

    handleUploadProps = () => {
        let {
        
        } = this.state
        let that = this
        return {
            customRequest({
              action,
              data,
              file,
              filename,
              headers,
              onError,
              onProgress,
              onSuccess,
              withCredentials
            }) {
              
                const S3 = s3Client;
                const TimeUnix =  moment().unix();

                const objParams = {
                    Bucket: process.env.REACT_APP_BUCKET_OBJ_NAME,
                    Key: `PARCEL-FILES/DROPPED-${TimeUnix}`,
                    Body: file,
                    ContentType: file.type,
                    ACL: 'public-read'
                };

                console.log(objParams)
                
                var s3upload = S3.putObject(objParams)
                    .on("httpUploadProgress", function({ loaded, total }) {
                        onProgress(
                            {
                            percent: Math.round((loaded / total) * 100)
                            },
                            file
                        );
                    })
                    .promise();

                s3upload.then(async data => {  

                        that.setState({
                            image_url_file : `https://${process.env.REACT_APP_BUCKET_OBJ_NAME}.${process.env.REACT_APP_BUCKET_OBJ_URL}/PARCEL-FILES/DROPPED-${TimeUnix}`,
                        })

                        onSuccess(data.response, file);
                        
                    }, err => {
                        onError();
                        console.log("Something went wrong");
                        console.log(err.code);
                        console.log(err.message)
                });

            }
        };
    }

    onProceed = async () => {
        let {actions} = this.props
        await actions.setValue({sds_info_display : false}, INFO_DISPLAY)
    }

    onSubmit = async () => {
        let {
            selected_payment_option,
            selected_courier,
            to_pay,
            tracking_number,
            item_specification,
            ref_link,
            image_url_file
        } = this.state

        await Parse.Cloud.run('addDropParcel', {
            courier_platform : selected_courier.id,
            payment_option : selected_payment_option.id,
            tracking_delivery_id : tracking_number,
            item_specification : item_specification,
            parcel_img_url : image_url_file,
            to_pay : to_pay ? to_pay : 0,
            ref_link : ref_link
        }).then(async result => {
            
            if(result.success){
                await this.setState(init_state)
                notification['success']({
                    message: 'Success',
                    description: 'Add Drop parcel Record success!'
                });

                
                await this.getSelections()
                await this.getDroppedParcels()

            }else{
                    notification['error']({
                        message: 'Error',
                        description: result.message
                    });
            }
        })
    }

    infoContent = () => {

        if(process.env.REACT_APP_ENVIRONMENT === 'DEV'){
            return (
                <Card>
                    <Col>
                        <h3 style={{color : '#F6C444'}}>
                            Shop-Drop-Ship
                        </h3>
                        <div style={{display : 'flex', alignItems : 'flex-end', justifyContent : 'flex-end', width : 250}}>
                            <span>Service by CDL Express</span>
                        </div>
                    </Col>
                    <Divider/>
                    
                    <h5>What is Shop-Drop-Ship service ?</h5><br/>
                    <p>
    
                    &nbsp; Shop-Drop-Ship is a convenient and cost-effective international shipping service that allows customers to shop from online stores in Philippines without worrying about the complexities of international shipping.<br/><br/>
    
                    &nbsp; The process is simple: customers can purchase products from their favorite online stores and have them shipped directly to our hub or distribution center operated by CDL Express. From there, the service will handle all of the necessary paperwork, packaging, and customs clearance, and transportation through our trusted courier to ensure that the package arrives safely and securely at its final destination. <br/><br/>
    
                    &nbsp; One of the main benefits of using Shop-Drop-Ship is that it offers significantly lower shipping rates compared to traditional international shipping methods. This is because the service leverages economies of scale and bulk shipping rates to provide customers with the best possible shipping rates. <br/><br/>
    
                    &nbsp; In addition, Shop-Drop-Ship offers a range of additional services to help customers save even more time and money, such as consolidation and repackaging services to reduce shipping costs.<br/><br/>
    
                    &nbsp; Overall, Shop-Drop-Ship is a reliable and cost-effective solution for anyone looking to shop online from Philippines stores and have their purchases shipped directly to their door, without the hassle and expense of traditional international shipping methods. <br/><br/>
                    </p>
                    <div style={{display : 'flex', alignItems : 'flex-end', justifyContent : 'flex-end'}}>
                        <Button type="primary" onClick={() => {this.onProceed()}}>I understand, Proceed </Button>
                    </div>
                </Card>
            
            );
        }else{
            <h3>Coming soon.</h3>
        }

        
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() { 
        let {InfoDisplay} = this.props
        let {sds_info_display} = InfoDisplay
        let {parcel_couriers, tracking_number, item_specification, ref_link, courier_selection, payment_selection, to_pay, selected_courier, selected_payment_option, image_url_file, dropped_items} = this.state

        const columns = [
            {
              title: 'Tracking / Delivery Id',
              dataIndex: 'tracking_delivery_id',
              key: 'tracking_delivery_id',
            },
            {
              title: 'Specification',
              dataIndex: 'item_specification',
              key: 'item_specification',
            },
            {
              title: 'Courier',
              dataIndex: 'courier',
              key: 'courier',
              render : (b) => (b? b.get('name') : '')
            },
            {
              title: 'Payment Opt.',
              dataIndex: 'payment_option',
              key: 'payment_option',
              render : (b) => (b? b.get('title') : '')
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Assigned Box',
                dataIndex: 'assigned_box',
                key: 'assigned_box',
            },
            {
                title: 'Actions',
                dataIndex: 'acts',
                key: 'acts',
            },
        ];


        return (
            <PageLayout>
            {
                process.env.REACT_APP_ENVIRONMENT === 'DEV' ?
                    !sds_info_display ?
                        
                        <Card style={{minHeight : 700}}>
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <h4>Drop Ship Manager</h4>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Col span={15}>
                                        <Card>
                                            <Button>Box Manager</Button>
                                        </Card>
                                    </Col>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <a><small style={{color : '#ff7979'}}>Prohibited Items ?</small></a>
                                </Col>
                            </Row>
                            
                            <Divider />
                            <Row gutter={20}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Card style={{minHeight : 400, marginBottom : 15}}>
                                        <h6>Drop Parcel</h6>
                                        <Divider />

                                            <Space direction='vertical' style={{width : '100%'}}>
                                            <Alert message=" Make sure to ship on our hub address: #30 Tandang Sora, Parang, Marikina City, PH 1809" type="info" showIcon />
                                            <Divider />

                                            <Row style={{marginBottom : 10}} gutter={10}>
                                                <Col xs={24} sm={24} md={12} lg={12}>
                                                    <span>Select Courier/ Platform</span>
                                                    <Select
                                                        placeholder={'Parcel Source/Courier'}
                                                        size='large'
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={courier_selection}
                                                        onChange={(e) => this.onSelectCourier(e)}
                                                        value={selected_courier ? selected_courier.id : null}
                                                    />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12}>
                                                    <span>Select Payment Option</span>
                                                    <Select
                                                        placeholder={'Payment Option'}
                                                        size='large'
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        options={payment_selection}
                                                        onChange={(e) => {this.onSelectPaymentOption(e)}}
                                                        value={selected_payment_option ? selected_payment_option.id : null}
                                                        />
                                                </Col>
                                            </Row>
                                            {
                                                selected_payment_option && selected_payment_option.payable?
                                                    <>
                                                        <span> Amount to Pay : </span>
                                                        <Input
                                                            value={to_pay}
                                                            addonBefore={'PHP'}
                                                            style={{marginBottom : 20}}
                                                            placeholder={'Amount'}
                                                            size='large'
                                                            onChange={(e) => {this.setState({to_pay : FILTER_FLOAT(e.target.value)}) }}
                                                        />
                                                        <Alert message="Make sure you have sufficient credit unless parcel is already paid including the courier fee." type="warning" showIcon />
                                                    </>
                                                : null
                                            }

                                                
                                                <Divider />
                                                <span > {selected_courier ? selected_courier.uid_type : 'Tracking # / Delivery ID ' }: </span>
                                                <Input 
                                                    disabled={selected_courier ? false : true}
                                                    style={{marginBottom : 20}}
                                                    placeholder={selected_courier ? selected_courier.uid_type : 'Unique ID'}
                                                    size='large'
                                                    onChange={(e) => {this.setState({tracking_number : e.target.value})}}
                                                    value={tracking_number}
                                                />
                                                {
                                                    selected_courier && selected_courier.ref_link?
                                                        <>
                                                            <span > {selected_courier.ref_link.name} : </span>
                                                            <Input 
                                                                style={{marginBottom : 20}}
                                                                placeholder={`eg. ${selected_courier.ref_link.sample}`}
                                                                size='large'
                                                                onChange={(e) => {this.setState({ref_link : e.target.value})}}
                                                                value={ref_link}
                                                            />
                                                        </>
                                                    : null
                                                }
                                            
                                                    
                                                <span >Item Specification : </span>
                                                <TextArea  
                                                    style={{marginBottom: 20}} 
                                                    size='large' 
                                                    placeholder='eg. H&M Shirt, Bench Bag' 
                                                    onChange={(e) => {this.setState({item_specification : e.target.value})}}
                                                    value={item_specification}
                                                />

                                                <span>Upload Parcel Image (optional) : </span>
                                                <Upload
                                                    listType={'picture-card'}
                                                    showUploadList={false}
                                                    style={{overflow : 'hidden'}}
                                                    accept=".doc,.docx,.jpg,.jpeg,.png,.pdf"
                                                    maxCount={1} multiple={false}
                                                    onChange={(e) => this.handleChangeFile(e)}
                                                    {...this.handleUploadProps()}
                                                    onRemove={(e) => this.handleCancelFile(e, image_url_file)}
                                                >
                                                    {
                                                        image_url_file ?
                                                        <img
                                                            src={image_url_file}
                                                            alt="parcel_image"
                                                            style={{
                                                                width: '90px',
                                                                height : '90px'
                                                            }}
                                                        />

                                                        : 
                                                        <div>
                                                        <PlusOutlined />
                                                        <div
                                                            style={{
                                                            marginTop: 8,
                                                            }}
                                                        >
                                                            Upload Image 
                                                        </div>
                                                        </div>
                                                    }
                                                </Upload>
                                            </Space>
                                            <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
                                                    <Button onClick={() => this.onSubmit()}>
                                                            Submit
                                                    </Button>
                                            </div>


                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                    <Card style={{minHeight : 400, overflowX : 'hidden'}}>
                                        <h6>My Parcels</h6>
                                        <Divider />
                                        <Table
                                            loading={this.state.is_table_loading}
                                            style={{height : 600}} 
                                            locale={{
                                                emptyText : <Empty 
                                                    style={{marginTop : 200}} 
                                                    image={<img src={EmptyImage}/>}
                                                    description="No Parcel Record"
                                                />
                                            }}
                                            dataSource={dropped_items} 
                                            columns={columns} 
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    
                    :
                        this.infoContent()

                : <Card>
                    <h3>Shop Drop Ship</h3>
                    <Divider />
                    <h4>Coming Soon..</h4>
                </Card>
            }
            </PageLayout>
            
        )
        
        
    }
}
 
const mapStateToProps = state => {
    return {
        InfoDisplay : state.InfoDisplay
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopDropShip)
// export default ShopDropShip;