import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, Table, Upload, Alert, Select, notification} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PageLayout from '../../page_layout'
import { Parallax } from 'react-scroll-parallax';
import moment from 'moment';
import _ from 'lodash'
import Parse from 'parse';
import {isMobile} from 'react-device-detect';
import EmptyImage from '../../assets/img/empty_box.png'
import BeforeUnloadComponent from 'react-beforeunload-component';
import { FILTER_FLOAT} from '../../utils'
import { hot } from 'react-hot-loader/root';
import PaymentForm from '../../component/payment/payment_form'

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";

class TopupPage extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <PageLayout>
                <h3 style={{marginLeft : 30}}>Top-Up</h3>
                <div style={{marginTop : 10}}>
                    <PaymentForm type={'Top-up'} description={'Account Credit Top-Up'} />
                </div>
            </PageLayout>
        );
    }
}
 
export default TopupPage;