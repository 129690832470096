import React, { useState, useEffect } from 'react';
import { Col, Row, Divider, Input, Button, Modal, Table } from 'antd';
import _ from 'lodash';
import Parse from 'parse'
import Swal from "sweetalert2";
import {NUMBER_WITH_COMMAS} from '../../utils/index'


const PurchasingStatus_Modal = (props) =>{


    useEffect(() => {
        console.log(props)
    }, [])

    const columns = [ 
        {
          title: 'Name',
          render: (row) => <b>{row.name}</b>,
        },
        {
          title: 'Order Quantity',
          render: (row) => <span>x {row.qty}</span>,
        },
        {
            title: 'Est. Price',
            render: (row) => <span>PHP {row.estimated_price ? row.estimated_price  : 0}</span>,
        },
        {
            title: 'Acquired',
            className : "col-success",
            render: (row) => <span>x {row.qty_acquired ? row.qty_acquired  : 0}</span>,
        },
        {
            title: 'Supplied Price',
            render: (row) => <span>PHP {row.confirmed_price ? row.confirmed_price  :  '--'}</span>,
        },
    ];

    return (
        <Modal
            onCancel={() => props.close()}
            open = {props.visible}
            title={<h4>Order Manifest</h4>}
            width={800}
            footer={null}
        >

            <div style={{height : '300 !important'}}>

                <Divider />
                
                <Table 
                    bordered
                    pagination={false}
                    columns={columns} 
                    dataSource={props.data.cart_items}
                />
                
            </div>


        </Modal>
    )

}

export default (PurchasingStatus_Modal)