import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, notification, Timeline} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';

import HeroImage from '../assets/img/hero_image.png'
import MAP from '../assets/img/purple_map.png'



class Track extends Component {
    // constructor(props) {
    //     super(props);
    // }
    
    state = {
        is_loading : false,
        tracking_number : '', //'5328185376',
        found : false,
        tracking_data : null
    }

    componentDidMount = () => {
        
    }

    onSubmit = () => {
        let {tracking_number} = this.state
        this.setState({is_loading : true})
        Parse.Cloud.run('client_getTracking', {tracking_number : tracking_number}).then(async result => {
            console.log(result)
            if(result.success){
                let events = []

                if(result.data.found){
                    if(result.data.carrier === 'fedex' || result.data.carrier === 'imove'){
                        await Promise.all(
                            result.data.track_data.scanEvents.map(async item => {
                                let color = ''
        
                                switch (item.derivedStatusCode) {
                                    case 'IN':
                                        color = '#535c68'
                                        break;
                                    case 'PU':
                                        color = '#F6C444'
                                        break;
                                    case 'IT':
                                        color = '#8D59F5'
                                        break;
                                    case 'DL':
                                        color = '#2ecc71'
                                        break;
                                    default:
                                        color = '#ff7979'
                                        break;
                                }
        
                                await events.push({
                                    color: color,
                                    children: (
                                    <>
                                        <p>{item.eventDescription}</p>
                                        <small>{item.scanLocation.city}, {item.scanLocation.countryName}, {item.scanLocation.postalCode}</small>
                                        <small>{item.date}</small>
                                    </>
                                    )
                                })
                            })
                        )
                    }else if(result.data.carrier === 'dhl'){
                        await Promise.all(
                            result.data.track_data.origin_info.trackinfo.map(async (item) => {
                                
                                let color = ''
        
                                if( item.Details.includes("MANILA")){                                
                                    if(item.StatusDescription.includes('Arrived at DHL Sort')){
                                        color = '#F6C444'
                                    }else{
                                        color = '#535c68'
                                    }
                                }else{
                                    if(item.checkpoint_status === "transit"){
                                        color = '#8D59F5'
                                    }
                                    if(item.checkpoint_status === "delivered"){
                                        color = '#2ecc71'
                                    }
                                    if(item.StatusDescription.includes("Please contact DHL")){
                                        color = '#ff7979'
                                    }
                                }
                                
                                await events.push({
                                    color: color,
                                    children: (
                                    <>
                                        <p>{item.StatusDescription}</p>
                                        <small>{item.Details}</small><br/>
                                        <small>{item.Date}</small>
                                    </>
                                    )
                                })

                            })
                        )
                    }
                }else{
                    notification['error']({
                        message: 'Oppps!',
                        description: `AWB # ${tracking_number} - was not found on our database.`
                    });
                }
              
                
                this.setState({
                    tracking_data : result.data.track_data,
                    track_events : events,
                    found : result.data.found, 
                    is_loading : false
                })
            }
        })
    }


    render() { 
        let {is_loading, tracking_number, tracking_data, track_events} = this.state
        return (
            <PageLayout
                title={'Tracking - CDLExpress'}
            >
                <Parallax speed={-10}>
                    <Row style={{paddingBottom : 300, marginTop : 100}}>
                        <Col span={24}>
                            <Space direction='vertical' style={{width : '100%'}} size={30}>
                                <h3 className='page-title'>Package Tracker</h3>
                                <div style={{maxHeight : 600, overflow: 'scroll'}}>
                                    <Input 
                                        onChange={(e) => {this.setState({tracking_number : e.target.value})}}
                                        value={tracking_number}
                                        addonBefore={<b>Tracking Number : </b>} 
                                        addonAfter={
                                            <Button 
                                                disabled={tracking_number.length > 0 ? false : true}
                                                onClick={() => {this.onSubmit()}}
                                            >
                                                Track Now!
                                            </Button>}
                                        size='large'/>
                                    <Divider />
                                    {
                                        !is_loading && !tracking_data  ?
                                            <Empty />
                                        : is_loading ?
                                            <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', textAlign : 'center'}}>
                                                <LoadingOutlined style={{ fontSize: 24 }} spin />
                                                <strong>Tracking your package, Please wait...</strong>
                                            </div>   
                                        : !is_loading && tracking_data ?
                                        <Timeline
                                            items={track_events}
                                        />
                                        : null 
                                    }
                                    
                                </div>
                            </Space>
                        </Col>
                    </Row>
                </Parallax>
            </PageLayout>
        );
    }
}
 
export default Track;