import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, notification, Timeline} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../redux/actions";
import { ROLE, USER_PROFILE } from '../redux/actions/ActionTypes'

class Login extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        username : '',
        password : ''
    }

    componentDidMount = async () => {

        console.log(await Parse.User.currentAsync())

        let isLoggedIn = Parse.User.current()
        
        if(isLoggedIn){
            window.location.href = '/'
        }
    }


    onLogin = async () => {
        let {username, password} = this.state
        let {actions} = this.props
        await Parse.User.logIn(username, password).then(async result => {
            if(result){
                await Promise.all([
                    await result.relation('Roles').query().each(async r => {
                        if(r.get('name') === 'USER' || r.get('name') === 'ADMIN'){
                            await Parse.Cloud.run('get_client_profile', {pdata : result.get('ProfileData').id}).then(async profileData => {
                                // console.log(profileData)

                                await actions.setValue({
                                    ROLE : r.get('name')
                                }, ROLE)

                                await actions.setValue({
                                    PROFILE : profileData.data
                                }, USER_PROFILE)
                            })

                            window.location.reload()
                        }
                    })
                ])
            }
            
        }).catch(e => {

            console.log(e)

            notification['error']({
                message: 'Error',
                description: e.message
            });
        })
    }


    render() {
        let {username, password} = this.state 

        let isLoggedIn = Parse.User.current()

        return (
            <PageLayout>
                {
                    !isLoggedIn ?
                        <Parallax>
                            <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', paddingBottom : 200}} >
                                <Card style={{width : 400, marginTop : 50}}>
                                    <h3>Login</h3>
                                    <Divider />
                                    <Space direction='vertical' style={{width : '100%'}}>
                                        <Input placeholder='Username' onChange={(e) => {this.setState({username : e.target.value})}} value={username}/>
                                        <Input placeholder='Password' type='password' onChange={(e) => {this.setState({password : e.target.value})}} value={password}/>
                                        <Divider/>
                                        <Row>
                                            <Col span={19}>
                                                <a href=''>forgot password?</a>
                                            </Col>
                                            <Col span={5}>
                                                <Button 
                                                    onClick={() => {this.onLogin()}}
                                                    >Sign in</Button>
                                            </Col>
                                        </Row>
                                        
                                    </Space>
                                </Card>
                            </div>
                        </Parallax>
                    : <h3>Redirecting..</h3>
                }
                
            </PageLayout>
        );
    }
}


const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
// export default Login;