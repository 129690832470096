import React, { Component } from 'react'
import { Col, Row, Card, Result , Input, Divider, Button, Empty, notification, Alert} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PageLayout from '../../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';
import CryptoJS from "crypto-js";
import {NUMBER_WITH_COMMAS} from '../../utils'


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";
import { ROLE, USER_PROFILE } from '../../redux/actions/ActionTypes'

const secretPass = process.env.REACT_APP_PARSE_MASTER_KEY;

class Login extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        error : false,
        payload : null,
        payment_complete : false,
        payment_ref : null,
        count_down : 10
    }

    componentDidMount = () => {

        let { ToPay } = this.props

        if(ToPay.type === "credit"){
            const bytes = CryptoJS.AES.decrypt(ToPay.data, secretPass);
            const payload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            
            this.setState({payload})
            // this.countDown()

        }else{ 
            this.setState({error : true})
        }

    }

    onProceedPayment = () => {


        let { ToPay } = this.props
        Parse.Cloud.run('PayUsingCredit', {payload : ToPay.data}).then(async result => {
            console.log(result)
            if(result.success){
                this.setState({payment_complete : true, payment_ref : result.payment_ref})
                this.countDown()
            }
        })
    }

    countDown = () => {
        
        var time = this.state.count_down;

        setInterval(function() {
            let that = this
            var seconds = time % 60;

            if (seconds.toString().length == 1) {
                seconds = "0" + seconds;
            }
           
            time--;
            that.setState({count_down : time})

            if (time == 0) {
                window.location.href = "/manage/account";
            }
        }.bind(this), 1000);
    }

    render() {
        let {payload, payment_complete, payment_ref, count_down} = this.state
        return (
            <PageLayout
                title="Payment - Credit"
            >
                <div style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent : 'center'}}>
                    <Card style={{minHeight : 300, marginBottom : 500, width : 600}}
                        title={!this.state.error ? "Payment Confirmation" : 'Opppps!'}
                    >

                        {
                            !this.state.error && payload && !payment_complete?
                                <>
                                    
                                    <h4>You are about to pay : </h4>
                                    <Row>    <h3 className='text-success'>PHP {NUMBER_WITH_COMMAS(payload.to_pay)}</h3>
                                        <h6 style={{marginLeft : 10, marginTop : 10}}>(Credit)</h6>
                                    </Row>
                                    <h6>
                                        for Invoice # {payload.invoice_id}
                                    </h6>
                                    <Divider />
                                    <Row style={{justifyContent : 'flex-end'}}>
                                            <Button 
                                                type="default" 
                                                shape="round" 
                                                style={{marginRight : 5}}
                                                size={'middle'}
                                                onClick={() => window.location.href = "/manage/account"}
                                            >
                                                Cancel
                                            </Button>
                                            <Button 
                                                type="success" 
                                                shape="round" 
                                                style={{marginRight : 5}}
                                                size={'middle'}
                                                onClick={() => this.onProceedPayment()}
                                            >
                                                Pay Now
                                            </Button>
                                    </Row>
                                </>
                            : payment_complete ?

                            <Result
                                status="success"
                                title="Payment Success"
                                subTitle={`Payment Ref: ${payment_ref}, redirecting back to client area in (${count_down}).`}
                                // extra={[
                                // <Button type="primary" key="console">
                                //     Go Console
                                // </Button>,
                                // <Button key="buy">Buy Again</Button>,
                                // ]}
                            />

                            :
                            <Alert message="Invalid Hash, Please close this window and repeat payment." type="error" />
                        }

                    </Card>
                </div>
            </PageLayout>
        );
    }
}


const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile,
        ToPay : state.ToPay
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
// export default Login;