import { combineReducers } from "redux";

import Role from './role'
import UserProfile from './userProfile'
import Token from './token'
import InfoDisplay from './info_displays'
import EPayment from './epayment'
import ActiveCart from './cart'
import ToPay from './to_pay'

export default combineReducers({
    Role,
    UserProfile,
    Token,
    InfoDisplay,
    EPayment,
    ActiveCart,
    ToPay
})