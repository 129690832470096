import { useState, useEffect } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, List, Typography, Avatar, Spin, Icon, Tag} from 'antd';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { EditOutlined, PlusOutlined, MinusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';
import _ from 'lodash'
import Swal from "sweetalert2";
import {NUMBER_WITH_COMMAS} from './../utils'

import {
    useSearchParams
} from "react-router-dom";


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../redux/actions";
import { ROLE, USER_PROFILE } from '../redux/actions/ActionTypes'
// import { parse } from 'dotenv';


const { Search } = Input;
const { Meta } = Card;


// let { id } = useParams();

function MyCart(props){
    let { UserProfile, actions } = props
    const [searchParams] = useSearchParams();

    const [is_loading_cart, setIsLoading] = useState(true)
    const [is_logged_in, setIsLoggedIn] = useState(false)
    const [customer_account_id, setCustomerAccountId] = useState(null)
    const [active_cart_id, setActiveCartId] = useState(null)
    const [cart_items, setCartItems] = useState([])
    const [estimated_total, setEstimatedTotal] = useState(0)
    const [consierge_price, setConsiergePrice] = useState(0)

   

    useEffect(() => {

        if(UserProfile && UserProfile.PROFILE){
            if(Parse.User.currentAsync()){
                let profile_id = UserProfile.PROFILE.id
                getConsiergeFee()
                setCustomerAccountId(profile_id)
                setIsLoggedIn(true)
                getCartItems(profile_id)
            }else{
                actions.setValue({PROFILE : null}, USER_PROFILE)
                window.location.replace("/login")
            }
        }

    }, [])

    const getConsiergeFee = async () => {
        setConsiergePrice((await Parse.Config.get()).get('concierge_price_kg'))
    }

    const getCartItems = async (id) => {
        // setIsLoading(true)

        await Parse.Cloud.run('getActiveCart', { customer_account_id : id}).then(async (result) => {

            if(result && result.success){
                let estimated_total = 0

                await Promise.all(
                    result.data.cart_items.map(item => {
                        estimated_total = estimated_total + ( item.estimated_price*item.qty )
                    })
                )

                setActiveCartId(result.data.id)
                setCartItems(result.data.cart_items)
                setEstimatedTotal(estimated_total)
                setIsLoading(false)
            }

        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }

    const updateCart = async(product_obj, action_type, qty) => {

        setIsLoading(true)

        if(product_obj && action_type){
            if(action_type === 'inc'){
                // INCREASE ITEM QTY
                
                let cart_item_temp = cart_items ? cart_items : []
                
                const itemToUpdate = cart_item_temp.find((item)=>item.id === product_obj.id);
                const itemToUpdateIndex = _.findIndex(cart_item_temp, (item) => item.id === product_obj.id)

                if(itemToUpdate){
                    itemToUpdate.qty = itemToUpdate.qty + 1
                    cart_item_temp[itemToUpdateIndex] = itemToUpdate

                }else{

                    // CREATE NEW CART ITEM OBJECT
                    await cart_item_temp.unshift(
                        {
                            id : product_obj.id,
                            name : product_obj.name,
                            img : product_obj.img,
                            qty : 1, 
                            estimated_price : product_obj.estimated_price,
                            tags : product_obj.tags
                        }
                    )
                }

                setCartItems(cart_item_temp)

            }

            if(action_type === 'dec'){
                // DECREASE ITEM QTY
                let cart_item_temp = cart_items ? cart_items : []
                
                const itemToUpdate = cart_item_temp.find((item)=>item.id === product_obj.id);
                const itemToUpdateIndex = _.findIndex(cart_item_temp, (item) => item.id === product_obj.id)

                if(itemToUpdate){
                    itemToUpdate.qty = itemToUpdate.qty - 1

                    if(itemToUpdate.qty <= 0){
                        // REMOVE OBJ FROM LIST IF QTY IS UPDATE TO EMPTY
                        await _.remove(cart_item_temp, (item) => item.id === product_obj.id);
                    }else{
                        cart_item_temp[itemToUpdateIndex] = itemToUpdate
                    }
                }

                setCartItems(cart_item_temp)

            }

        }else if(product_obj && qty){

            let cart_item_temp = cart_items ? cart_items : []
                
            const itemToUpdate = cart_item_temp.find((item)=>item.id === product_obj.id);
            const itemToUpdateIndex = _.findIndex(cart_item_temp, (item) => item.id === product_obj.id)

            if(itemToUpdate && qty > 0){
                itemToUpdate.qty = parseInt(qty)
                cart_item_temp[itemToUpdateIndex] = itemToUpdate

            } else if(itemToUpdate && qty <= 0){
                // REMOVE OBJ FROM LIST IF QTY IS UPDATE TO EMPTY
                await _.remove(cart_item_temp, (item) => item.id === product_obj.id);

            }else{
                // CREATE NEW CART ITEM OBJECT
                await cart_item_temp.unshift(
                    {
                        id : product_obj.id,
                        name : product_obj.name,
                        img : product_obj.img,
                        qty : 1, 
                        estimated_price : product_obj.estimated_price,
                        tags : product_obj.tags
                    }
                )
            }

            setCartItems(cart_item_temp)
        }

        await Parse.Cloud.run('updateCartItems', {customer_account_id : customer_account_id, cart_id : active_cart_id, cart_items : cart_items}).then(async result => {
            setIsLoading(false)
            await getCartItems(customer_account_id)
        }).catch(e => {
            console.log(e)
            setIsLoading(false)
        })


    }

    const submitCart = async() => {
        
        Swal.fire({
            title: 'Warning!',
            icon: 'warning',
            text: 'Are you sure you want to Place this cart to Order?',
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton : true,
            confirmButtonColor : "#2ecc71",
            confirmButtonText : 'Confirm',
            
        }).then(async result => {

            if(result.isConfirmed){
                await Parse.Cloud.run('submitCart', {account_id : customer_account_id, cart_id : active_cart_id}).then(async result => {
                    
                    if(result.success){
                        Swal.fire({
                            title: 'Cart has been placed!',
                            icon: 'success',
                            text: 'Please wait, until we complete your order.',
                            allowOutsideClick: false,
                            showCancelButton: true,
                            showConfirmButton : true,
                            confirmButtonColor : "#2ecc71",
                            confirmButtonText : 'Ok',
                            cancelButtonColor : "#2ecc71",
                            cancelButtonText : 'Continue shopping',
                        }).then(result => {

                            if(result.isConfirmed){
                                let {UserProfile} = props 
                                if(UserProfile && UserProfile.PROFILE){
                                    let profile_id = UserProfile.PROFILE.id
                                    getConsiergeFee()
                                    setCustomerAccountId(profile_id)
                                    setIsLoggedIn(true)
                                    getCartItems(profile_id)
                                }
                            }else if(result.isDismissed){
                                window.location.replace("/pabili");
                            }
                            
                        })
                    }else{
                        console.log(result)
                    }
                })
            }
        })
       

    }
    

    return (
        <PageLayout
            title={'My Cart'}
        >
            {
                UserProfile && UserProfile.PROFILE && Parse.User.currentAsync() ?
                    <Parallax speed={-10}>
                        <Row style={{paddingBottom : 100, minHeight : 800, marginTop : 50}}>
                            <Col span={24}>
                                <Space direction='vertical' style={{width : '100%'}} size={30}>
                                    <h3 className='page-title'>My Cart</h3>
                                    <div style={{maxHeight : 750}}>
                                        <Row style={{width : '100%'}} gutter={24}>
                                                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                        <Card 
                                                            title={
                                                            <Row style={{width : '100%'}}>
                                                                <Col span={18}>
                                                                    <strong style={{color : '#777'}}>CART ID : {active_cart_id ?  active_cart_id.toUpperCase() : '--'}</strong>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <strong>TOTAL ITEMS : {cart_items.length}</strong>
                                                                </Col>
                                                            </Row>}
                                                            style={{maxHeight : 650, width : '100%', overflowY: 'auto'}} loading={is_loading_cart}>
                                                            
                                                            {
                                                                cart_items ?
                                                                    <List
                                                                        bordered
                                                                        dataSource={cart_items}
                                                                        renderItem={(item) => (
                                                                            <List.Item>
                                                                                <List.Item.Meta
                                                                                    avatar={<Avatar shape="square" size={64} src={item.img}/>}
                                                                                    title={<a>{item.name}</a>}
                                                                                    description={<span>
                                                                                        <Row>
                                                                                            <Col span={{xs: 24, sm: 24, md: 15, lg: 15}}>
                                                                                                Est. Price : <b>PHP {item.estimated_price} / pc</b><br/>
                                                                                                Total : <b>PHP {item.estimated_price*item.qty}</b><br/><br/>                                                                                      
                                                                                            </Col>
                                                                                            
                                                                                            <Col  span={{xs: 10, sm: 10, md: 5, lg: 5}}> 
                                                                                                <span style={{display : 'flex', flexDirection : 'row'}}>
                                                                                                    <MinusOutlined 
                                                                                                        key="decrease" 
                                                                                                        onClick={() => {updateCart(item, 'dec')}}
                                                                                                    />
                                                                                                    <Input
                                                                                                        style={{marginLeft : 10, marginRight : 10}} 
                                                                                                        placeholder='0' 
                                                                                                        type='number'
                                                                                                        // value={item.qty}
                                                                                                        value={cart_items.findIndex(p => p.id == item.id) >= 0 ? cart_items[cart_items.findIndex(p => p.id == item.id)].qty : null }  
                                                                                                        onChange={(e) => {updateCart(item, null, e.target.value)}}
                                                                                                    />
                                                                                                    <PlusOutlined key="increase" onClick={() => {updateCart(item, 'inc')}}/>
                                                                                                </span>
                                                                                                
                                                                                                    {/* Qty : {item.qty} */}
                                                                                            </Col>    
                                                                                        </Row>
                                                                                        <Row>
                                                                                            {
                                                                                                _.some(item.tags, _.unary(_.partialRight(_.includes, 'Frozen'))) ?
                                                                                                    <small style={{color : '#ff7979'}}>Note : Frozen goods must be shipped within (5) days upon purchase.</small>
                                                                                                : null
                                                                                            }
                                                                                        </Row>
                                                                                    </span>}
                                                                                    />
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                : null
                                                            }
                                                        
                                                        </Card>
                                                </Col>
                                                <Col  xs={24} sm={24} md={24} lg={10} xl={10}>
                                                        <Card>
                                                            <div style={{display : 'flex', flexDirection : 'column', minHeight : 200}}> 
                                                                <h4>Available Credit : PHP {
                                                                    props.UserProfile && props.UserProfile.PROFILE && props.UserProfile.PROFILE.credit_data ? 
                                                                        parseFloat(props.UserProfile.PROFILE.credit_data.running_credits).toFixed(2) 
                                                                    : 0
                                                                    }
                                                                </h4>
                                                                
                                                                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                                    <Tag 
                                                                        icon={<ShoppingCartOutlined />} 
                                                                        color="#f1c40f"
                                                                    >
                                                                        On-Float : PHP {props.UserProfile && props.UserProfile.PROFILE && props.UserProfile.PROFILE.credit_data? props.UserProfile.PROFILE.credit_data.float_credits : 0} 
                                                                    </Tag>
                                                                </Col>
                                                                
                                                                
                                                                <Divider />
                                                                <h5>Estimated Total : PHP {NUMBER_WITH_COMMAS(estimated_total)}</h5>
                                                                <small>This is estimated sum only, price vary may apply due to diffirent suppliers price.  </small>

                                                                <Divider />

                                                                <h6>Pabili Service fee per kg : PHP {consierge_price}</h6>

                                                                <Divider />
                                                                <div style={{
                                                                    marginTop: 'auto', 
                                                                    alignSelf : props.UserProfile && 
                                                                    props.UserProfile.PROFILE && 
                                                                    props.UserProfile.PROFILE.credit_data && 
                                                                    props.UserProfile.PROFILE.credit_data.running_credits >= estimated_total ?
                                                                    'flex-end'
                                                                    : 'flex-start'
                                                                }}>

                                                                    {
                                                                        props.UserProfile && props.UserProfile.PROFILE && props.UserProfile.PROFILE.credit_data ? 
                                                                        
                                                                            props.UserProfile.PROFILE.credit_data.running_credits >= estimated_total ?
                                                                                <Button 
                                                                                    disabled={cart_items.length <= 0 ? true : false}
                                                                                type="primary" shape="round" size={'large'} onClick={() => {submitCart()}}>
                                                                                    SUBMIT CART
                                                                                </Button>
                                                                            : <>
                                                                                <h6 className="text-danger">Insufficient credit!</h6>
                                                                                {/* <a className='text-link'></a> */}
                                                                                <Button 
                                                                                    type="primary" 
                                                                                    shape="round" 
                                                                                    // icon={<FontAwesomeIcon icon={faListCheck} style={{marginRight : 5}}/>} size={'middle'}
                                                                                    // onClick={() => {this.setState({show_manifest_modal : true, selected_cart : row})}}
                                                                                >
                                                                                    Top-Up Now
                                                                                </Button>
                                                                            </>
                                                                            
                                                                        : <h6 className="text-danger">Insufficient credit, <a>Click to top-up</a></h6>
                                                                    }
                                                                    
                                                                </div>

                                                            </div>
                                                            
                                                        </Card>
                                                </Col>
                                        </Row>
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Parallax>
                :  window.location.href = '/login'

            }
            
        </PageLayout>
    )

}


const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCart)

// export default MyCart;