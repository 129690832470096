// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./base_style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DESKTOP */
@media (min-width : 500px) {
    .hero-title{
        margin-top : 200px
    }
    .hero-title-wrapper {
        height: 170px;
    }

    .hero-image {
        width : 145%;
        height : 0 auto;
    }
}

/* MOBILE */
@media (max-width : 500px) {

    .hero-title{
        padding-left: 30px;
        margin-top: 180px;
    }

    .hero-subtitle {
        /* width: 450px; */
    }

    .hero-title-wrapper {
        height: 150px;
    }

    #hero-head-title {
        font-size: 40px !important;
        line-height: normal;
    }

    .hero-image {
        width : 305%;
        height : 0 auto;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/styles/index.css"],"names":[],"mappings":"AAGA,YAAY;AACZ;IACI;QACI;IACJ;IACA;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;AACJ;;AAEA,WAAW;AACX;;IAEI;QACI,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,0BAA0B;QAC1B,mBAAmB;IACvB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;;AAEJ","sourcesContent":["@import './base_style.css';\n\n\n/* DESKTOP */\n@media (min-width : 500px) {\n    .hero-title{\n        margin-top : 200px\n    }\n    .hero-title-wrapper {\n        height: 170px;\n    }\n\n    .hero-image {\n        width : 145%;\n        height : 0 auto;\n    }\n}\n\n/* MOBILE */\n@media (max-width : 500px) {\n\n    .hero-title{\n        padding-left: 30px;\n        margin-top: 180px;\n    }\n\n    .hero-subtitle {\n        /* width: 450px; */\n    }\n\n    .hero-title-wrapper {\n        height: 150px;\n    }\n\n    #hero-head-title {\n        font-size: 40px !important;\n        line-height: normal;\n    }\n\n    .hero-image {\n        width : 305%;\n        height : 0 auto;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
