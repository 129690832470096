import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, List, Empty, notification, Badge, Pagination} from 'antd';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { EditOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';
import _ from 'lodash'


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../redux/actions";
import { ROLE, USER_PROFILE } from '../redux/actions/ActionTypes'



const { Search } = Input;
const { Meta } = Card;

class Shop extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        is_logged_in : false,
        is_loading : false,
        product_list_bak : [],
        product_list : [],
        searched_list : [],
        active_cart_id : null,
        cart_items : []
    }

    componentDidMount = async () => {

        if(await Parse.User.currentAsync()){
            let {UserProfile} = this.props 

            if(UserProfile && UserProfile.PROFILE){
                let profile_id = UserProfile.PROFILE.id
                this.setState({is_logged_in : true})
                await this.getActiveCart(profile_id)
                await this.getInitialProducts()
            }else{
                this.setState({is_logged_in : false})
            }
        }else{
             this.setState({is_logged_in : false})
        }
    }

    getInitialProducts = async() => {

        Parse.Cloud.run('getAllProducts').then(result => {
            
            if(result.success){
                this.setState({
                    product_list : result.data,
                    product_list_bak : result.data
                })
                
                // console.log(result.data)
            }
        })
    }

    getActiveCart = async(id) => {

        await Parse.Cloud.run('getActiveCart', { customer_account_id : id}).then(result => {
            // console.log('MY ACTIVE CART ', result)
            this.setState({
                active_cart_id : result.data.id,
                cart_items : result.data.cart_items
            })
        })    
    }

    updateCart = async(product_obj, action_type, qty) => {
        
        let {active_cart_id} = this.state
        let {UserProfile} = this.props 
        let profile_id = UserProfile.PROFILE.id

        if(product_obj && action_type){
            if(action_type === 'inc'){
                // INCREASE ITEM QTY
                let cart_items = this.state.cart_items ? this.state.cart_items : []
                
                const itemToUpdate = cart_items.find((item)=>item.id === product_obj.id);
                const itemToUpdateIndex = _.findIndex(cart_items, (item) => item.id === product_obj.id)

                if(itemToUpdate){
                    itemToUpdate.qty = itemToUpdate.qty + 1
                    cart_items[itemToUpdateIndex] = itemToUpdate
                }else{
                    // CREATE NEW CART ITEM OBJECT

                    // let img = product_obj.product_image.split('?')[0]

                    await cart_items.unshift(
                        {
                            id : product_obj.id,
                            name : product_obj.name,
                            img : product_obj.product_image,
                            qty : 1, 
                            estimated_price : product_obj.estimated_price,
                            tags :product_obj.tags
                        }
                    )
                }

                await this.setState({cart_items : cart_items})
                // console.log(this.state.cart_items)

            }

            if(action_type === 'dec'){
                // DECREASE ITEM QTY
                let cart_items = this.state.cart_items ? this.state.cart_items : []
                
                const itemToUpdate = cart_items.find((item)=>item.id === product_obj.id);
                const itemToUpdateIndex = _.findIndex(cart_items, (item) => item.id === product_obj.id)

                if(itemToUpdate){
                    itemToUpdate.qty = itemToUpdate.qty - 1

                    if(itemToUpdate.qty <= 0){
                        // REMOVE OBJ FROM LIST IF QTY IS UPDATE TO EMPTY
                        await _.remove(cart_items, (item) => item.id === product_obj.id);
                    }else{
                        cart_items[itemToUpdateIndex] = itemToUpdate
                    }
                }

                await this.setState({cart_items : cart_items})


            }
        }else if(product_obj && qty){

            let cart_items = this.state.cart_items ? this.state.cart_items : []
                
            const itemToUpdate = cart_items.find((item)=>item.id === product_obj.id);
            const itemToUpdateIndex = _.findIndex(cart_items, (item) => item.id === product_obj.id)

            if(itemToUpdate && qty > 0){
                itemToUpdate.qty = parseInt(qty)
                cart_items[itemToUpdateIndex] = itemToUpdate

            } else if(itemToUpdate && qty <= 0){
                // REMOVE OBJ FROM LIST IF QTY IS UPDATE TO EMPTY
                await _.remove(cart_items, (item) => item.id === product_obj.id);

            }else{
                // CREATE NEW CART ITEM OBJECT
                await cart_items.unshift(
                    {
                        id : product_obj.id,
                        name : product_obj.name,
                        img : product_obj.product_image,
                        qty : parseInt(qty), 
                        estimated_price : product_obj.estimated_price,
                        tags : product_obj.tags
                    }
                )
            }

            await this.setState({cart_items : cart_items})
        }

        await Parse.Cloud.run('updateCartItems', {customer_account_id : profile_id, cart_id : active_cart_id, cart_items : this.state.cart_items})

    }

    onSearch = async(query) => {
        console.log('QUERY ', query)
        let {product_list, product_list_bak} = this.state
        
        if(!query){
            await this.setState({product_list : []})
            await this.setState({product_list : product_list_bak})
        }else{
            let result = await product_list.filter(o => o.name.toLowerCase().includes(query.toLowerCase()));
            await this.setState({product_list : result})
        }

    }

    scrape_test = async () => {
        // Parse.Cloud.run('scrape_ever_products').then(result => {
        //     console.log(result)
        // })
    }

    render() {
        let {is_loading, is_logged_in, product_list, cart_items, searched_list} = this.state

        return (
            <PageLayout>
                <Parallax speed={-10}>
                    <Row style={{paddingBottom : 300, minHeight : 800}}>
                        <Col span={24}>
                            <Space direction='vertical' style={{width : '100%', paddingTop : 80}} size={30}>
                                <Row>
                                    <Col span={20}>
                                        <h3 className='page-title'><span style={{color : '#f5a905'}}>Pabili</span> <span style={{color : '#8D59F5'}}>Service</span></h3>
                                    </Col>

                                    {
                                        is_logged_in ?
                                            <Col span={4}>
                                                    <Badge count={this.state.cart_items.length} showZero>
                                                        <Button size='small' type="primary" shape="rounded" 
                                                            icon={<FontAwesomeIcon icon={faCartShopping} style={{ marginRight : 10}}/>} 
                                                            style={{marginLeft : 10}}
                                                            href='/my-cart'
                                                        > 
                                                            View My Cart 
                                                        </Button>
                                                    </Badge>
                                            </Col>
                                        : null
                                    }
                                    
                                </Row>

                                {/* <Card style={{textAlign : 'center'}}> */}
                                    {
                                        is_logged_in ?
                                            <div>
                                                <Divider/>
                                                <h6 style={{marginLeft : 20}}>Collections</h6>
                                                <Row style={{justifyContent : 'center'}}>
                                                    <Col>
                                                        <Card className='category-container'>
                                                            <h5>All Product</h5>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className='category-container'>
                                                            <h5>Delicacies</h5>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card  className='category-container'>
                                                            <h5>Groceries</h5>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card  className='category-container'>
                                                            <h5>Beauty & Health Care</h5>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card  className='category-container'>
                                                            <h5>Frozen Goods</h5>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Divider/>
                                                <Row style={{marginBottom : 40, marginLeft : 20}}>
                                                    <Search
                                                        placeholder="Search Product"
                                                        allowClear
                                                        onChange={(e) => {this.onSearch(e.target.value.length > 0 ? e.target.value : null)}}
                                                        onC
                                                        style={{
                                                            width: 200,
                                                        }}
                                                    />
                                                </Row>
                                                    <List
                                                        grid={{
                                                        gutter: 16,
                                                        xs: 1,
                                                        sm: 2,
                                                        md: 4,
                                                        lg: 4,
                                                        xl: 4,
                                                        xxl: 6,
                                                        }}
                                                        dataSource={product_list}
                                                        pagination={{
                                                            defaultPageSize : 24,
                                                            position : 'bottom'
                                                        }}
                                                        renderItem={(item) => (
                                                        <List.Item>
                                                            <Card
                                                                style={{ margin: 10}}
                                                                cover={
                                                                    <img
                                                                        alt={item.name}
                                                                        src={item.product_image}
                                                                    />
                                                                }
                                                                actions={[
                                                                
                                                                <MinusOutlined 
                                                                    key="decrease" 
                                                                    onClick={() => {this.updateCart(item, 'dec')}}    
                                                                />,
                                                                <Input 
                                                                    placeholder='0' 
                                                                    type='number'
                                                                    value={cart_items.findIndex(p => p.id == item.id) >= 0 ? cart_items[cart_items.findIndex(p => p.id == item.id)].qty : null }  
                                                                    onChange={(e) => {this.updateCart(item, null, e.target.value)}}
                                                                />,
                                                                <PlusOutlined key="increase" onClick={() => {this.updateCart(item, 'inc')}}/>
                                                                ]}

                                                            >
                                                                <Meta
                                                                    title={item.name}
                                                                    description={`Est Price : PHP ${item.estimated_price}`}
                                                                />
                                                            </Card>
                                                        </List.Item>
                                                        )}
                                                    />
                                            </div>

                                            // <h4>Express Shopping Product List.</h4>

                                        :  <h4>Please login to start Express Shopping.</h4>
                                    }



                            </Space>
                        </Col>
                    </Row>
                </Parallax>
            </PageLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop)

// export default Shop;