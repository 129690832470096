import React, { useState, useEffect } from 'react';


export default function shop_card (props) {

    let {
        img
    } = props


    return (
        <img src={img}
            className='category-shop-card zoom'
        />
    )

}