// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* DESKTOP */
@media (min-width : 500px) {

}

/* MOBILE */
@media (max-width : 500px) {

        .page-title {
            margin-left: 30px;
        }

}`, "",{"version":3,"sources":["webpack://./src/pages/styles/track.css"],"names":[],"mappings":";AACA,YAAY;AACZ;;AAEA;;AAEA,WAAW;AACX;;QAEQ;YACI,iBAAiB;QACrB;;AAER","sourcesContent":["\n/* DESKTOP */\n@media (min-width : 500px) {\n\n}\n\n/* MOBILE */\n@media (max-width : 500px) {\n\n        .page-title {\n            margin-left: 30px;\n        }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
