import React, { useState, useEffect } from 'react';
import {Row, Col, Layout, Button, Badge, Menu, Drawer, Divider, Tooltip, List, Avatar, Card} from 'antd'
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { EditOutlined, PlusOutlined, UserOutlined, ShoppingCartOutlined, BellOutlined } from '@ant-design/icons';
import Parse from 'parse'
import {NUMBER_WITH_COMMAS} from '../utils'

import {
    faBars,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";


import Logo from '../assets/img/logo.png'
import BookPickUp from './global/book_pickup_modal'

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../redux/actions";
import { ROLE, USER_PROFILE, ACTIVE_CART } from '../redux/actions/ActionTypes'


const { Header} = Layout;


const Head = (props) =>{

    let {actions, ActiveCart } = props

    const [bookPickupVisible, setBookPickupVisible] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    const [notification_data, setNotifications] = useState([])
    const [cart, setCartItems] = useState([])

    useEffect(() => {

        let {UserProfile} = props

        // console.log('HEADER PROPS ', props)

        if(UserProfile && UserProfile.PROFILE && !Parse.User.currentAsync()){
                actions.setValue({PROFILE : null}, USER_PROFILE)
        }else if(UserProfile && UserProfile.PROFILE && Parse.User.currentAsync()){
            let profile_id = UserProfile.PROFILE.id
            getActiveCart(profile_id)
            getNotifications()
        }else{
            actions.setValue({PROFILE : null}, USER_PROFILE)
            // window.location.replace("/login")
        }

    }, [])

    const getActiveCart = async (id) => {
        
        await Parse.Cloud.run('getActiveCart', { customer_account_id : id}).then(async (result) => {
            if(result && result.success){
                let estimated_total = 0
                await Promise.all(
                    result.data.cart_items.map(item => {
                        estimated_total = estimated_total + ( item.estimated_price*item.qty )
                    })
                )
                actions.setValue({cart_items : result.data.cart_items}, ACTIVE_CART)
            }

        }).catch(e => {
            console.log(e)
        })
    }

    const getNotifications = async() => {
        let user = await Parse.User.currentAsync()

        if(user){
            await Parse.Cloud.run('getClientNotifications', {id : user.id}).then(result => {
                if(result){
                    setNotifications(result.data)
                }
            })
        }
    }

    return (
        <>
            <Header className='menu-header' style={{backgroundColor : 'rgba(0,0,0,0)'}}>
                <title>{props.title}</title>
                <span className="app-menu" >
                    <AppMenu
                        {...props}
                        notification_data = {notification_data}
                        cart_count = {ActiveCart.cart_items.length}
                        pickupVisible={bookPickupVisible}
                        pickupVisibleControl={(e) => setBookPickupVisible(e)}
                    />
                </span>
                <span className="app-drawer">
                    <AppDrawer
                        {...props}
                        notification_data = {notification_data}
                        cart_count = {ActiveCart.cart_items.length}
                        drawerVisible={showDrawer}
                        drawerVisibleControl={(e) => setShowDrawer(e)}
                        pickupVisible={bookPickupVisible}
                        pickupVisibleControl={(e) => setBookPickupVisible(e)}
                    />
                </span>
            </Header>
            <BookPickUp visible={bookPickupVisible} close={() => {setBookPickupVisible(false)}}/>
        </>
    )

}

const logout = (props) => {
    let {actions} = props
    Parse.User.logOut().then(async result => {
        await actions.setValue({resetState : true}, USER_PROFILE)
        console.log(props)
        window.location.reload()
    })
}

const AppMenu = (props) => {
    let {cart_count, notification_data} = props

    return (
        <Row>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <a href='/'>
                    <img src={Logo} style={{width : '50%'}}/>
                </a>
            </Col>
            <Col xs={13} sm={13} md={11} lg={11} xl={11}>
                <Menu
                    theme={'light'}
                    mode={'horizontal'}
                >

                    <Menu.Item key={1}>
                        <a style={{fontWeight : 'bold'}} href={'/track'}>Tracking</a>
                    </Menu.Item>
                    <Menu.SubMenu key={2} title={ <a style={{fontWeight : 'bold', color : '#F6C444'}}> Concierge Services </a> }>
                        <Menu.Item key={3}>
                            <a
                                href="/pabili"
                            >
                                Pabili Service
                            </a> 
                        </Menu.Item>
                        <Menu.Item key={4}  disable={true}>
                            <a
                                // href="/shop-drop"
                            >
                                Shop & Drop Service
                            </a> 
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key={5}>
                        {
                            Parse.User.current() ?
                            <a
                                onClick={() => {props.pickupVisibleControl(true)}}
                                style={{fontWeight : 'bold', color : '#8D59F5'}}
                            >Book a Pick-up</a>
                            :
                            <a
                                href='/login'
                                style={{fontWeight : 'bold', color : '#8D59F5'}}
                            >
                                Book a Pick-up
                            </a>
                        }

                    </Menu.Item>
                    <Menu.Item key={6}>
                        <a
                            href='/pricing'
                            style={{fontWeight : 'bold', color : '#8D59F5'}}
                        >
                            Pricing
                        </a>
                    </Menu.Item>

                </Menu>
            </Col>

            <Col span={5}>
                {
                    Parse.User.current() && props.UserProfile.PROFILE ?
                            <>

                                <Menu
                                    mode={ 'horizontal'}
                                >

                                        <Menu.SubMenu 
                                            title={ 
                                                notification_data.unread && notification_data.unread > 0 ?
                                                <>
                                                    <Badge count={notification_data.unread}> 
                                                        <BellOutlined style={{fontSize : 18, marginRight : 10}}/>
                                                    </Badge>
                                                    Notification
                                                </>
                                                : 
                                            <> 
                                                <BellOutlined style={{fontSize : 18, marginRight : 10}}/>
                                                Notification
                                            </>
                                            }
                                        >
                                            <div style={{width : 400, minHeight : 300, maxHeight : 600, overflowY : 'auto', padding : 10}}>
                                                <List
                                                    size="small"
                                                    dataSource={notification_data.list}
                                                    renderItem={(item, index) =>
                                                        <List.Item key={item.id}>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    !item.is_viewed ?
                                                                        <Badge dot><ShoppingCartOutlined style={{fontSize : 18, marginRight : 10}}/></Badge>
                                                                    : <ShoppingCartOutlined style={{fontSize : 18, marginRight : 10}}/>
                                                                }
                                                                title={<span className={`text-${item.event_type}`} key={item.id}>{item.data.title}</span>}
                                                                description={item.data.description}
                                                            />
                                                        </List.Item>
                                                    }
                                                />
                                            </div>
                                        </Menu.SubMenu>
                                        <Menu.Item>
                                            <a href='/my-cart'> 
                                                <Badge count={cart_count}>
                                                    <ShoppingCartOutlined style={{fontSize : 18}} /> 
                                                </Badge><span style={{marginLeft : 15}}>Cart</span>
                                            </a>
                                        </Menu.Item>
                                        <Menu.SubMenu key={6} title={ <><UserOutlined style={{fontSize : 18, marginRight : 10}}/> {`${props.UserProfile.PROFILE.first_name} ${props.UserProfile.PROFILE.last_name}`}</>}>
                                            <Menu.Item key={7}>
                                                <b>Credit : PHP {props.UserProfile && props.UserProfile.PROFILE && props.UserProfile.PROFILE.credit_data?  parseFloat(props.UserProfile.PROFILE.credit_data.running_credits).toFixed(2) : 0}</b>
                                                <Tooltip title="Top-up">
                                                    <a href="/top-up">
                                                        <Button size='small' type="primary" shape="rounded" icon={
                                                            <FontAwesomeIcon icon={faPlus}/>
                                                        } style={{marginLeft : 10}}/>
                                                    </a>
                                                </Tooltip>
                                            </Menu.Item>
                                            <Divider />
                                            <Menu.Item key={8}>
                                                <a href='/manage/account'>Transactions</a>
                                            </Menu.Item>
                                            {/* <Menu.Item key={9}>
                                                <a href='/my-purchases'> My Purchases</a>
                                            </Menu.Item> */}
                                            <Menu.Item key={10}>
                                                <a>Inventory</a>
                                            </Menu.Item>
                                            <Menu.Item key={11}>
                                                <a>Billing</a>
                                            </Menu.Item>
                                            <Divider />
                                            <Menu.Item key={12}>
                                                <a danger onClick={() => logout(props)}>Logout</a>
                                            </Menu.Item>
                                        </Menu.SubMenu>

                                </Menu>
                            </>

                    :
                        <Menu
                            // inlineIndent={24}
                            mode={'horizontal'}>
                            <Menu.Item key={4}>
                                <a>Sign-Up</a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a href='/login'>Login</a>
                            </Menu.Item>
                        </Menu>
                }


            </Col>
        </Row>
    )
}

const AppDrawer = (props) => {

    let {cart_count} = props

    return (
        <Row>
            <Col span={20} >
                <a href='/' style={{marginLeft : 30}}>
                    <img src={Logo} style={{width : '50%'}}/>
                </a>
            </Col>
            <Col span={4}>
                <Button onClick={() => props.drawerVisibleControl(true)}>
                    <FontAwesomeIcon
                        icon={faBars}
                    />
                </Button>
            </Col>

            <Drawer
                title={<a href='/'>
                    <img src={Logo} style={{width : '50%'}}/>
                </a>}
                placement={'left'}
                closable={true}
                closeIcon={
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                    />}
                maskClosable={true}
                onClose={() => props.drawerVisibleControl(false)}
                open={props.drawerVisible}
                // key={placement}
            >
                <Menu
                    mode='inline'
                >
                    <Menu.Item key={1}>
                        <a style={{fontWeight : 'bold'}} href={'/track'}>Tracking</a>
                    </Menu.Item>
                    <Menu.SubMenu key={2} title={ <a style={{fontWeight : 'bold', color : '#F6C444'}}> Concierge Services </a> }>
                        <Menu.Item key={3}>
                            <a
                                href="/pabili"
                            >
                                Pabili Service
                            </a> 
                        </Menu.Item>
                        <Menu.Item key={4}  disable={true}>
                            <a
                                // href="/shop-drop"
                            >
                                Shop & Drop Service
                            </a> 
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key={5}>
                        <a
                            onClick={() => {props.pickupVisibleControl(true)}}
                            style={{fontWeight : 'bold', color : '#8D59F5'}}
                        >Book a Pick-up</a>
                    </Menu.Item>

                    <Divider />

                    <Menu>
                    {
                        Parse.User.current() && props.UserProfile.PROFILE ?
                                <>
                                <h5>Hello! {props.UserProfile.PROFILE.first_name},</h5>
                                

                                <Menu.Item key={6}>
                                    <b>Credit : PHP {props.UserProfile && props.UserProfile.PROFILE.credit_data?  parseFloat(props.UserProfile.PROFILE.credit_data.running_credits).toFixed(2) : 0}</b>
                                    <Tooltip title="Top-up">
                                        <a href="/top-up">
                                            <Button size='small' type="primary" shape="rounded" icon={
                                                <FontAwesomeIcon icon={faPlus}/>
                                            } style={{marginLeft : 10}}/>
                                        </a>
                                    </Tooltip>
                                </Menu.Item>
                                <Divider />
                                <Menu.Item key={7}>
                                    <a href='/manage/account'>My Account</a>
                                </Menu.Item>
                                {/* <Menu.Item key={8}>
                                    <a href='/manage'>My Purchases</a>
                                </Menu.Item> */}
                                <Menu.Item key={9}>
                                    <a href='/my-cart'> 
                                        <Badge count={cart_count}>
                                            <ShoppingCartOutlined style={{fontSize : 18}} /> 
                                        </Badge><span style={{marginLeft : 15}}>Cart</span>
                                    </a>
                                </Menu.Item>

                                <Menu.Item key={99}>
                                    <Button danger onClick={() => {logout(props)}}>Logout</Button>
                                </Menu.Item>

                                </>
                        :
                        <>
                            <Menu.Item key={4}>
                                <a>Sign-Up</a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a href='/login'>Login</a>
                            </Menu.Item>
                        </>

                    }
                   </Menu>
                </Menu>
            </Drawer>
        </Row>
    )
}


const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile,
        ActiveCart : state.ActiveCart
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };

      return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Head)

// export default Head;

