import React, { Component } from 'react'
import { Col, Layout, Row } from 'antd';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Typewriter from 'typewriter-effect/dist/core';
import HeroImage from '../assets/img/hero_model_2.png'
import MAP from '../assets/img/purple_map.png'



class Home extends Component {
    constructor(props) {
        super(props);
    }
    state = { 

     }

    componentDidMount =() => {
        var app = document.getElementById('hero-head-title');

        var typewriter = new Typewriter(app, {
        loop: true,
        delay: 50,
        });

        typewriter
        .pauseFor(1000)
        .typeString('Make Things <br/><strong style="color:#a">Delivered<span style="color:#F6C444">.<span></strong>')
        .pauseFor(5000)
        .deleteChars(18)
        .typeString('Delivery <br/><strong>Simple<span style="color:#F6C444">.<span></strong>')
        .pauseFor(5000)
        .deleteChars(22)
        // .typeString(`We don't sugar coat, <strong style="color:#F6C444">We Ship.</strong>`)
        // .pauseFor(5000)
        .start();
    }

    render() { 
        return (
            <PageLayout>
                <Parallax speed={-10}>
                        <Col
                            xs={{span: 24}} 
                            sm={{span: 24}} 
                            md={{span: 18, offset : 3}} 
                            lg={{span: 18, offset : 3}} 
                            xl={{span: 18, offset : 3}}
                            
                        >
                             <Row>
                                <Col 
                                    xs={{span: 14}} 
                                    sm={{span: 14}} 
                                    md={{span: 12}} 
                                    lg={{span: 12}} 
                                    xl={{span: 12}}
                                >
                                    <div className='hero-title'>
                                        <div className='hero-title-wrapper'>
                                            <h1 id='hero-head-title' ></h1>
                                        </div>
                                        <h6 className='hero-subtitle'>
                                            Your one-stop-shop for hassle-free delivery right to your doorstep.
                                        </h6>
                                    </div>  
                                </Col>
                                <Col xs={8} sm={8} md={10} lg={10} xl={10}>
                                    <img className='hero-image' src={HeroImage}/>
                                </Col>
                            </Row>
                        </Col>
                </Parallax>
            </PageLayout>
        );
    }
}
 
export default Home;