import React, { useState, useEffect } from 'react';
import { Col, Row, Divider, Input, Button, Modal, Table } from 'antd';
import _ from 'lodash';
import Parse from 'parse'
import Swal from "sweetalert2";
import {NUMBER_WITH_COMMAS} from '../../utils/index'

const InvoiceModal = (props) =>{


    useEffect(() => {
        console.log('MODAL PROPS ', props)
        generateInvoiceData()
    },[])

    const [invoice_data, setInvoiceData] = useState(null)


    const generateInvoiceData = async() => {

        let {type, credit_usage, shop_cart, sds, total_net_amount} = props.data
        let prep_data = []
 

        if(shop_cart){
            prep_data.push({
                description : `${type}-${shop_cart.incremental_id}`,
                amount : shop_cart.total_amount_purchased ? shop_cart.total_amount_purchased : 0,
                action : 'increase'
            })

            prep_data.push({
                description : 'SERVICE FEE',
                amount : shop_cart.service_fee ? shop_cart.service_fee : 0,
                action : 'increase'
            })
        }

        // MAPPING CREDIT_USAGE

        await Promise.all(
            credit_usage.map(async (record) => {
                prep_data.push({
                    description : `CREDITS APPLIED`,
                    amount : `- ${record.amount}`,
                    action : 'decrease'
                })
            })
        )


        await setInvoiceData(prep_data)


    }


    const columns = [ 
        {
          title: 'Description',
          render: (row) => <b>{row.description}</b>,
        },
        {
          title: 'Amount (PHP)',
          render: (row) => <span style={{color : row.action === 'increase' ? 'green' : 'red'}}>PHP {row.amount}</span>,
        },
    ];

    return (

        <Modal
            onCancel={() => props.close()}
            open = {props.visible}
            title={`Invoice #: ${props.data.incremental_id}`}
            footer={
                <Col style={{marginTop : 50}}>
                    <Divider />
                    <h6>Subtotal:  ₱ {NUMBER_WITH_COMMAS(props.data.total_net_amount)}</h6>
                    <h4>Total: ₱ {NUMBER_WITH_COMMAS(props.data.total_net_amount)}</h4>
                </Col>
            }
        >
            <div style={{height : '300 !important'}}>
                {
                    invoice_data ?
                        <Table 
                            pagination={false}
                            columns={columns} 
                            dataSource={invoice_data ? invoice_data : null}
                        />
                    : null
                }
                
                <small className='text-danger'>NOTE : Late fees of 2% of total balance due are applied on the 3rd day once the invoice was issued.</small>
            </div>
        </Modal>
    )

}

export default (InvoiceModal)