import React, { Component } from 'react'
import { Col, Row, Divider, Input, Button, Card, Modal, Form, Space, Alert, Steps, notification, Descriptions, DatePicker, Spin, Result } from 'antd';
import PlacesAutocomplete, {
    geocodeByAddress,

} from 'react-places-autocomplete';
import _ from 'lodash';
import Parse from 'parse'

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";

import { TOKEN, GENERATE_TOKEN } from '../../redux/actions/ActionTypes'
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const {TextArea} = Input;

class BookPickupModal extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        step : 0,
        isLoading : false,
        booking_obj : null,

        enable_step_1: false,
        enable_step_2 : false,

        loading : false,
        full_name : '',
        mobile_number : '',
        full_str_address : '',
        receiver_address : {},
        prefered_date_time : '',
        land_mark : '',

        input_verification_code : '',
        
        verified : false
    }


    componentDidUpdate = async () => {
        let { step , full_name, mobile_number, prefered_date_time, full_str_address } = this.state

        if(step === 0){

            if(full_name.length > 0 && mobile_number.length > 0 && full_str_address.length > 0 && prefered_date_time.length > 0 && !this.state.enable_step_1){
                this.setState({enable_step_1 : true})
            }
            
            if(full_name.length == 0 && this.state.enable_step_1 || 
                mobile_number.length == 0 && this.state.enable_step_1 || 
                full_str_address.length == 0 && this.state.enable_step_1 ||
                prefered_date_time.length == 0 && this.state.enable_step_1
            ){
                await this.setState({enable_step_1 : false})
            }
        }

    }

    handleChangeReceiverAddress = async (address) => {

        // console.log(address)
        let {receiver_address} = this.state
        // let {actions, NewBoxFormReducer} = this.props
        // let {receiver_address} = NewBoxFormReducer
        
        let exist_obj = receiver_address
        exist_obj.full_str_address = address
        await this.setState({receiver_address : exist_obj, full_str_address : address})
        // console.log(this.state)

    }

    handleSelectReceiverAddress = async (address) => {

        await geocodeByAddress(address)
        .then(async results => {
            // find address parameters
            let postal_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'postal_code')})
            let street_number_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'street_number')}) //street_number 
            let street_name_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'route')}) // "route"
            let city_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'locality') ? _.includes(o.types, 'locality') : _.includes(o.types, "sublocality")})
            let state_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'administrative_area_level_1')})
            let country_obj = _.find(results[0].address_components, (o) => {return _.includes(o.types, 'country')})
            // let selectedCountry = _.find(CountriesReducer.list, {name : country_obj.long_name})
            
            let address_obj = {
                full_str_address : results[0].formatted_address,
                street : street_number_obj && street_name_obj ? `${street_number_obj.long_name} ${street_name_obj.long_name}` : '',
                // street_2 : address_2 ? address_2 : '',
                city : city_obj ? city_obj.long_name : '',
                city_short : city_obj ? city_obj.short_name : '',
                state_province : state_obj ? state_obj.long_name : '',
                state_province_short : state_obj ? state_obj.short_name : '',
                country : country_obj ? country_obj.long_name : '',
                country_short : country_obj ? country_obj.short_name : '',
                postal_code : postal_obj ? postal_obj.long_name : null,
            }

            await this.setState({
                receiver_address : address_obj, 
                full_str_address : results[0].formatted_address
            })

            console.log(results[0].formatted_address)
        })
        .catch(error => console.error('Error', error));
    }


    step1Content = () => {
        let {receiver_address, full_str_address, full_name, mobile_number}= this.state
        return (
            <>
                <Alert
                    banner
                    message={'Our pick-up coverage is within Metro Manila & Rizal areas only.'}
                />
                <br/>
                <Space direction='vertical' style={{width : '100%'}}>
         
                    <Form
                        layout="vertical"
                        initialValues={{
                            fullName: full_name,
                            mobileNumber : mobile_number
                        }}
                        onValuesChange={this.onFirstStepChangeValue}
                        requiredMark={true}
                    >
                        <Form.Item 
                            label="Full Name" 
                            required 
                            tooltip="This is a required field"
                            name="fullName"
                        >
                            <Input placeholder='Full Name' 
                                value={full_name}
                                onChange={(e) => this.setState({full_name : e.target.value})}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Mobile Number" 
                            required 
                            tooltip="This is a required field"
                            name="mobileNumber"
                        >
                            <Input 
                                placeholder='9123456890' 
                                addonBefore="+63" 
                                value={mobile_number}
                                onChange={(e) => this.setState({mobile_number : e.target.value})}
                            />
                        </Form.Item>

                    </Form>
                    
                    <span><small style={{color :'red'}}>*</small> Prefered Time & Date</span>
                    <DatePicker 
                        showTime={{
                            format: 'HH:mm',
                            use12Hours : true
                        }}
                        onChange={(e, d) => { this.setState({prefered_date_time : d})}} 
                    />
                    <PlacesAutocomplete
                        value={full_str_address}
                        onChange={this.handleChangeReceiverAddress}
                        onSelect={(e) => this.handleSelectReceiverAddress(e)}
                        searchOptions={{
                            componentRestrictions: {country: "ph"},
                            types: ['address']
                        }}
                        highlightFirstSuggestion={true}
                        googleCallbackName={'googleCallBack'}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                    <span><small style={{color :'red'}}>*</small> Address</span>
                                    <Input style={{width : '100%', marginTop : 10}} 
                                        {...getInputProps({
                                            placeholder: 'Address ...',
                                            className: 'location-search-input',
                                        })}
                                    />

                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            key={`sug-${index}`}
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    
                    <span> Land mark</span>
                    <TextArea placeholder={`eg. Purple gate beside aling nena's store` } onChange={(e) => {this.setState({land_mark : e.target.value})}}/>
                    
                </Space>
            </>
        )
    }

    step2Content = () => {
        let {Token} = this.props
        let {verification_sent, verified_success, verified_failed} = Token
        let {mobile_number, input_verification_code} = this.state

        return (
            <>
                <Alert
                    banner
                    type="info"
                    message={`Please check your mobile number +63${mobile_number} for verification code.`}
                />
                <Divider />
                <Row gutter={20}>
                    <Col span={18}>
                        {

                            !verified_success ?
                                <>
                                    <Input 
                                        status={verification_sent ? 'warning' : null} 
                                        placeholder={'Verification Code' }
                                        addonAfter={null}
                                        onChange={(e) => {this.setState({input_verification_code : e.target.value})}}
                                    />
                                    {
                                        verification_sent ?
                                            <small style={{color : '#2ecc71'}}>Verification Sent!</small>
                                        : null
                                    }
                                </>
                            : <h3>Verification Success!</h3>
                        }
                        
                    </Col>
                    <Col span={5}>
                        {
                            !verified_success ?
                                input_verification_code.length >= 6 ?
                                    <Button onClick={() => {this.verifyCode()}}>Verify Code</Button>
                                : <Button onClick={() => {this.sendVerification()}}>Send Code</Button>
                            :null
                        }
                        
                    </Col>
                </Row>
                    
                
            </>
        )
    }

    step3Content = () => {
        let {isLoading,full_name, mobile_number,  full_str_address, prefered_date_time, land_mark, booking_obj} = this.state

        if(isLoading){
            return (
            <div style={{display : 'flex' , flexDirection : 'column', justifyContent : 'center', alignItems : 'center'}}>
                <Spin 
                    indicator={
                        <LoadingOutlined style={{ fontSize: 50 }} spin />
                    } 
                />
                <h6 style={{marginTop : 30}}>Loading...</h6>
            </div>)
        }else if(booking_obj){
            return (<Result
                status="success"
                title={`Pick-up  reference : ${booking_obj.id.toUpperCase()}`}
                subTitle="Scheduled successfully."
            />)
        }else{
            return (
                <Descriptions title="Pickup Details" bordered layout={'vertical'}>
                        <Descriptions.Item label="Name of Representative" span={2}>
                            {full_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Mobile #" span={2}>
                            +63{mobile_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Prefered Date & Tome of Pick-up" span={4}>
                            {moment(prefered_date_time).format('lll')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Address" span={4}>
                            {full_str_address}
                        </Descriptions.Item>
                        <Descriptions.Item label="Location Land mark" span={4}>
                            {land_mark}
                        </Descriptions.Item>
                </Descriptions>
            )
        }
    }

    sendVerification = async() => {
        let {actions} = this.props
        let {mobile_number} = this.state

        await actions.setValue({resetState : true}, TOKEN)

        await Parse.Cloud.run('createVerificationRecord', {
            mobile_number : mobile_number,
            category : 'BOOK_PICKUP'
        }).then(async result => {
            if(result.success){
                await actions.setValue({
                    token : result.data.token, 
                    verification_sent : true
                }, TOKEN)

                console.log(this.props.Token)
            }
        })
    }

    verifyCode = () => {

        console.log(this.props)
        let {mobile_number, input_verification_code} = this.state
        let {Token, actions} = this.props

        Parse.Cloud.run('verifyCode', {
            verification_code : input_verification_code,
            token : Token.token,
            mobile_number : `+63${mobile_number}`
        }).then(async result => {

            if(result.success){
                await actions.setValue({
                    verified_success : true,
                    verified_failed : false
                }, TOKEN)

                await this.setState({enable_step_2 : true})
            }else{
                notification['error']({
                    message: 'Error',
                    description: result.message
                });
            }
        })
    }

    bookPickUp = async () => {

        let {
            full_name,
            full_str_address,
            prefered_date_time,
            mobile_number,
            land_mark
        } = this.state

        let {actions} = this.props

        await this.setState({isLoading : true})

        Parse.Cloud.run('savePickupBooking', {
            full_name,
            contact_number : `+63${mobile_number}`,
            address : full_str_address,
            date_scheduled : moment(prefered_date_time).format(),
            land_mark,
            user_id : Parse.User.current().id
        }).then(result => {

            if(result.success){
                this.setState({isLoading : false, booking_obj : result.data})
                actions.setValue({resetState : true}, TOKEN)

            }else{
                notification['error']({
                    message: 'Error',
                    description: result.message
                });
            }
            
        }).catch(e => {
            notification['error']({
                message: 'Error',
                description: e.message
            });
        })
    }

    render() { 
        let {step, booking_obj, loading, full_name, receiver_address, mobile_number, enable_step_1, enable_step_2} = this.state
        let {visible, close} = this.props

        let steps = [
            {
                title: 'Details',
                content: this.step1Content(),
            },
            {
                title: 'Verification',
                content:  this.step2Content(),
            },
            {
                title: 'Book',
                content: this.step3Content(),
            },
        ]
        

        return (
            <Modal
                destroyOnClose={booking_obj ? true : false}
                onCancel={this.props.close}
                open = {visible}
                title="Book a Pick-up"
                footer={<Row>
                    <Col span={4}>
                        {/* <Button onClick={this.props.close}>Cancel</Button> */}
                    </Col>
                    <Col span={20}>
                        {
                            !booking_obj ?
                                <Button disabled={step == 0? true : false} onClick={() => {this.setState({step : step - 1})}}>Back</Button>
                            : null
                        }
                        
                        
                        {
                            !booking_obj ?
                                enable_step_1 || enable_step_2 ?

                                    step === 2 ?
                                        <Button
                                            type='primary'
                                            onClick={() => this.bookPickUp()}
                                        >
                                            Book a Pick-up
                                        </Button>
                                    :
                                        <Button
                                            onClick={() => this.setState({step : step + 1})}
                                        >
                                            Next
                                        </Button>
                                :
                                    <Button
                                        disabled={true}
                                    >
                                        Next
                                    </Button>
                            :   <Button
                                    danger
                                    onClick={() => close()}
                                >
                                    Close
                                </Button>
                                }

                        {/* <Button onClick={() => this.bookPickUp()}>
                            TEST
                        </Button> */}
                    </Col>
                </Row>}
            >
                <div style={{height : '300 !important'}}>
                    <Divider/>

                    <Steps
                        current={step}
                        items={steps}
                    />
                    <div>
                        <Divider/>
                        {steps[step].content}
                        <Divider/>
                    </div>

                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        Token : state.Token
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(BookPickupModal)
 
// export default BookPickupModal;