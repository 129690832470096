// import * as dotenv from 'dotenv'
// require('dotenv').config();

import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router'
// import '@antd/dist/antd.css';
import 'antd-button-color/dist/css/style.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from 'react-scroll-parallax';
import {
  RouterProvider,
} from "react-router-dom";
import {ConfigProvider} from 'antd'
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'




// dotenv.config()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ParallaxProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#8D59F5',
                colorLink: '#F6C444'
              },
            }}
          >
            <RouterProvider router={Router()} />
          </ConfigProvider>
        </ParallaxProvider>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
