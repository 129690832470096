import React, { Component } from 'react'
import { Col, Row, Card, Space, Table, Input, Divider, Button, Empty, List, notification, Tabs, Tag} from 'antd';
import { LoadingOutlined, OrderedListOutlined, PayCircleOutlined } from '@ant-design/icons';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faFileText, faListCheck } from "@fortawesome/free-solid-svg-icons";
import PageLayout from '../../page_layout'
import { Parallax } from 'react-scroll-parallax';
import moment from 'moment';
import _ from 'lodash'
import Parse from 'parse';
import {isMobile} from 'react-device-detect';
import {NUMBER_WITH_COMMAS} from '../../utils/index'

import InvoiceModal from '../../component/billing/invoice_modal';
import PurchasingStatus_Modal from '../../component/purchases/purchasing_status_modal';
import PaymentOptionModal from '../../component/payment/payment_option';


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";
import { ROLE, USER_PROFILE, ACTIVE_CART } from '../../redux/actions/ActionTypes'


class Manage extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        activeTabKey : null,
        shipment_loading : true,
        shipments_data : [],
        purchases_data : null,
        billing_data : [],

        selected_cart : null,
        selected_billing : null,
        show_invoice_modal : false,
        show_manifest_modal : false,
        show_payment_modal : false
    }

    componentDidMount = async () => {


        let { UserProfile } = this.props

        if(await Parse.User.currentAsync() && UserProfile){
            
            Parse.Cloud.run('get_client_shipments', {user_id : Parse.User.current().id}).then(result => {
                // console.log('get_client_shipments', result)
                if(result.success){

                    let sortedData =_.orderBy(result.data, [(obj) => new Date(obj.createdAt)], ['desc'])

                    this.setState({
                        shipments_data : sortedData,
                        shipment_loading : false
                    })
                }else{
                    notification['error']({
                        message: 'Error',
                        description: result.message
                    });
                }
            }).catch(e => {
                notification['error']({
                    message: 'Error',
                    description: e.message
                });
            })

            Parse.Cloud.run('get_client_purchases', {account_id : UserProfile.PROFILE.id}).then(result => {

                if(result.success){
                    // let sortedData =_.orderBy(result.data, [(obj) => new Date(obj.createdAt)], ['desc'])

                    this.setState({
                        purchases_data : result.data,
                    })

                    console.log(result.data)

                }else{
                    notification['error']({
                        message: 'Error',
                        description: result.message
                    });
                }

            }).catch(e => {
                notification['error']({
                    message: 'Error',
                    description: e.message
                });
            })

            Parse.Cloud.run('getAccountBilling', {user_id : Parse.User.current().id}).then(result => {
                // console.log(result)
                if(result.success){
                    this.setState({billing_data : result.data})
                }
            })

        }else{
            window.location.replace("/login")
        }

    }

    shipmentsContent = () => {
        let {shipments_data, shipment_loading} = this.state


        const columns = [ 
          {
            title: 'SID',
            key: 'id',
            render: (row) => <b>#{row.incremental_id}</b>,
          },
          {
            title: 'Package Incld.',
            render: (row) => <a>{row.shipment_boxes.length} pc(s)</a>,
          },
          {
            title: 'Invoice',
            render: (row) =><Col>
                <small>Invoice Ref : {row.invoice_code}</small><br/>
                <Button>View Invoice</Button>
            </Col>,
          },
          {
            title: 'Handled by',
            render: (row) => <a>{row.assigned_agent.id}</a>,
          },
          {
            title: 'Created At',
            render: (row) => <small>{moment(row.createdAt).format('lll')}</small>,
          },
          {
            title: 'Payment Status',
            render: (row) => row.payment_approved ? <Tag color="green">Approved</Tag> : row.payment_pending ? <Tag color="blue">On-Process</Tag> : <Tag color="gold">Waiting for payment</Tag> ,
          },
        //   {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //       <Space size="middle">
        //         <a>Invite {record.id}</a>
        //         <a>Delete</a>
        //       </Space>
        //     ),
        //   },
        ];
        
        return (
            <Table columns={columns} dataSource={shipments_data} />
        )

    }

    purchasesContent = () => {
        
        const tabs = [
            {
                label: `Queue`,
                key: 0,
                children: this.state.purchases_data && this.state.purchases_data.to_process? this.purchases_queueContent() : <i>Loading...</i>,
            }, 
            {
                label: `On-Process`,
                key: 1,
                children: this.state.purchases_data && this.state.purchases_data.to_process? this.purchases_toProcessContent() : <i>Loading...</i>,
            },  
            {
                label: `Completed`,
                key: 2,
                children: this.state.purchases_data && this.state.purchases_data.completed? this.purchases_completedContent() : <i>Loading...</i>,
                
            },
        ]

        return (
            <Tabs
                tabPosition={'top'}
                items={tabs}
            />
        )

    }

    purchases_queueContent = () => {

        let {purchases_data} = this.state


        const columns = [ 
            {
              title: 'CART #',
              key: 'id',
              render: (row) => <b>#{row.incremental_id}</b>,
            },
            {
              title: 'Items Incld.',
              render: (row) => <a>{row.total_qty} pc(s)</a>,
            },
            {
                title: '',
                render: (row) => <span className='text-secondary'>Please wait, while we're reviewing your orders.</span>,
              }
        ];
          
        return (
            <Table columns={columns} dataSource={purchases_data.to_review} />
        )
    }

    purchases_toProcessContent = () => {

        let {purchases_data} = this.state


        const columns = [ 
            {
              title: 'CART #',
              key: 'id',
              render: (row) => <b>#{row.incremental_id}</b>,
            },
            {
              title: 'Items Qty.',
              render: (row) => <a>{row.total_qty} pc(s)</a>,
            },
            {
              title: 'Handled by',
              render: (row) => <a>{row.handled_by}</a>,
            },
            {
                title: 'Total Weight',
                render: (row) =><Col>
                    <b>{row.total_weight ? `${row.total_weight} kg` : '-- kg'}</b><br/>
                </Col>,
              },
            {
              title: '',
              render: (row) => <Button 
                                    type="success" 
                                    shape="round" 
                                    icon={<FontAwesomeIcon icon={faListCheck} style={{marginRight : 5}}/>} size={'middle'}
                                    onClick={() => {this.setState({show_manifest_modal : true, selected_cart : row})}}
                                >
                                    Order Manifest
                                </Button>,
            }
        ];
          
        return (
            <Table columns={columns} dataSource={purchases_data.to_process} />
        )
    }

    purchases_completedContent = () => {
        let {purchases_data} = this.state


        const columns = [ 
            {
              title: 'CART #',
              key: 'id',
              render: (row) => <b>#{row.incremental_id}</b>,
            },
            {
              title: 'Items Qty.',
              render: (row) => <a>{row.total_qty} pc(s)</a>,
            },
            {
              title: 'Handled by',
              render: (row) => <a>{row.handled_by}</a>,
            },
            {
                title: 'Total Weight',
                render: (row) =><Col>
                    <b>{row.total_weight ? `${row.total_weight} kg` : '-- kg'}</b><br/>
                </Col>,
            },
            {
                title: 'Paid',
                render: (row) => <b className={`text-${row.is_paid ? "success" : "default"}`}>{row.is_paid ? 'Yes' : 'No'}</b>,
            },
            {
              title: 'Invoice',
              render: (row) => <Button 
                                type={"dashed"} 
                                shape="round"  
                                onClick={() => {this.setState({activeTabKey : 4})}}>
                                Invoice # {row.billing ? row.billing.invoice_number : 0}</Button>,
            },
            {
                title: '',
                render: (row) =><Button 
                                    type="success" 
                                    shape="round" 
                                    icon={<FontAwesomeIcon icon={faListCheck} style={{marginRight : 5}}/>} size={'middle'}
                                    onClick={() => {this.setState({show_manifest_modal : true, selected_cart : row})}}
                                >
                                    Order Manifest
                                </Button>
                ,
              }
        ];
          
        return (
            <Table columns={columns} dataSource={purchases_data.completed} />
        )
    }

    billingContent = () => {
        let {billing_data} = this.state
        const columns = [ 
            {
              title: 'Description',
              key: 'incremental_id',
              render: (row) => <b>Invoice #{row.incremental_id}</b>,
            },
            {
              title: 'Reference',
              render: (row) => <a>{row.type}-{row.shop_cart.incremental_id}</a>,
            },
            {
                title: 'Amount',
                render: (row) => `PHP ${NUMBER_WITH_COMMAS(parseFloat(row.total_net_amount).toFixed(2))}`,
            },
            {
                title: 'Paid',
                render: (row) => <b className={`text-${row.is_paid ? 'success' : 'default'}`}>{row.is_paid ? 'Yes' : 'No'}</b>,
            },
            {
                title: 'Issued',
                render: (row) => <small>
                    {moment(row.createdAt).format('lll')}
                </small>,
            },
            {
                title: '',
                render: (row) => <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-evenly'}}>
                    <Button 
                        type="dashed" 
                        shape="round" 
                        icon={<FontAwesomeIcon icon={faFileText} style={{marginRight : 5}}/>} 
                        size={'middle'} 
                        onClick={() => this.setState({show_invoice_modal : true, selected_billing : row})}
                    >
                        Invoice
                    </Button>
                </div>,
            },
            {
                title: '',
                render: (row) => 
                    <>
                    {!row.is_paid ?
                            <Button 
                                type="success" 
                                shape="round" 
                                icon={<FontAwesomeIcon icon={faCreditCard} 
                                style={{marginRight : 5}}/>} 
                                size={'middle'}
                                onClick={() => this.setState({show_payment_modal : true, selected_billing : row})}
                            >
                                Pay Now
                            </Button>
                    : null}
                    </>
                
            },
        ];

        return (
            <>
                <Table columns={columns} dataSource={billing_data} />
                {
                    this.state.show_invoice_modal ?
                        <InvoiceModal 
                            visible={this.state.show_invoice_modal}
                            close={() => {this.setState({show_invoice_modal : false})}}
                            data={this.state.selected_billing}
                        />
                    : null

                }
                
            </>
        )
    }


    render() { 

        let {  selected_cart, selected_billing, show_manifest_modal, show_payment_modal } = this.state
        let { UserProfile } = this.props
        let { PROFILE } = UserProfile

        const tabs = [
            {
                label: `Shipments`,
                key: 1,
                children: this.shipmentsContent(),
            },
            {
                label: `Purchases`,
                key: 2,
                children: this.purchasesContent(),
            },
            // {
            //     label: `Requested Pick-up`,
            //     key: 3,
            //     children: `Scheduled Pick-up Records`,
            // },
            {
                label: `Billing & Payment`,
                key: 4,
                children: this.billingContent(),
            }
            
        ]

        return (
            <PageLayout
                title={'Account Transactions'}
            >
                
                {/* <Parallax speed={-10}> */}
                    <>
                   
                    <Card style={{minHeight : 800}}>
                        <Col span={24}>
                            <Row style={{width : '100%'}}>
                                <Col span={12}>
                                    <h4>Account Transactions</h4>
                                </Col>
                                <Col span={12}>
                                    <h6>Available Credit : {`PHP ${PROFILE.credit_data.running_credits ? NUMBER_WITH_COMMAS(PROFILE.credit_data.running_credits) : '0.00'}`}</h6>
                                </Col>
                            </Row>
                        </Col>
                        
                        <Divider/>

                        <Tabs
                            defaultActiveKey={1}
                            activeKey={this.state.activeTabKey ? this.state.activeTabKey : 1}
                            onTabClick={(e) => (this.setState({activeTabKey : e}))}
                            tabPosition={isMobile ? 'top': 'left'}
                            items={tabs}
                        />

                    </Card>
                    {
                        show_manifest_modal ?
                            <PurchasingStatus_Modal
                                visible={show_manifest_modal}
                                close={() => this.setState({show_manifest_modal : false})}
                                data={selected_cart}
                            />
                        : null
                    }

                    {
                        show_payment_modal ?
                            <PaymentOptionModal
                                visible={show_payment_modal}
                                close={() => this.setState({show_payment_modal : false})}
                                data={selected_billing}
                            />
                        : null
                    }
                    </>
                {/* </Parallax> */}
            </PageLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
    };
  
    return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage)
 
// export default Manage;