import React, { Component } from 'react';
import {Layout, Row, Input, Col} from 'antd'
import Lottie from 'lottie-react-web'
import Logo from './assets/img/logo.png'
import lottie_maintenance from './assets/under_construction.json'

class Maintenance extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <Layout style={{height : '100vh', width : '100vw', backgroundColor : '#fff', display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center'}}>
                <img src={Logo} style={{height : 80, width : 320, marginTop : 150, marginBottom : 20}}/>
                <Lottie
                    style={{position : 'relative', top : -100}}
                    options={{
                    animationData: lottie_maintenance
                    }}
                />
                {/* <h1>Under Maintenance</h1> */}
            </Layout>
        );
    }
}
 
export default Maintenance;