import React from 'react'
import Parse from 'parse';
import {
    createBrowserRouter,
    // useRouteError
} from "react-router-dom";


import MaintenancePage from './maintenance'
import HomePage from './pages/index'
import TrackPage from './pages/track'
import LoginPage from './pages/login'
import Login from './pages/login';

import MyAccountPage from './pages/manage/account'
import ShopDropShip from './pages/sds'
import TopUpPage from './pages/topup'
import PaymentCredit from './pages/payment/payment_credit'


import ShoppingPage from './pages/shop'
import MyCartPage from './pages/cart'
import PabiliPage from './pages/pabili'
import PricingPage from './pages/pricing'

// import ErrorBoundary from 'antd/es/alert/ErrorBoundary';


try {
    Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, null, process.env.REACT_APP_PARSE_JAVASCRIPTKEY);
    Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL    
    Parse.masterKey = process.env.REACT_APP_PARSE_MASTER_KEY
    Parse.javaScriptKey = process.env.REACT_APP_PARSE_JAVASCRIPTKEY

} catch (error) {
    console.log(error)
}
  

function Router(props) {
    let maintenance = process.env.REACT_APP_MAINTENANCE

    let page_arr = [
        {
          path: "/",
          element: <HomePage />,
        //   errorElement : ErrorBoundary()
        },
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "/track",
            element: <TrackPage />,
        },
        {
            path : "/manage/account",
            element : <MyAccountPage />
        },
        {
            path : '/shop-drop-ship',
            element : <ShopDropShip />
        },
        {
            path : '/top-up',
            element : <TopUpPage />
        },
        {
            path  : '/pabili',
            element : <PabiliPage />
        },
        {
            path  : '/my-cart',
            element : <MyCartPage/>
        },
        {
            path  : '/payment/credit',
            element : <PaymentCredit/>
        },
        {
            path  : '/pricing',
            element : <PricingPage/>
        }
        
    ]

    let maintenance_page_arr = [
        {
            path: "/",
            element: <MaintenancePage />,
        }
    ]


    return createBrowserRouter(
        maintenance === 'TRUE' ? maintenance_page_arr : page_arr
    );

    
}

// function ErrorBoundary() {
//     let error = useRouteError();
//     console.error(error);
//     // Uncaught ReferenceError: path is not defined
//     return (<div>Dang!</div>);
// }

export default Router;
// export default connect(mapStateToProps, mapDispatchToProps)(Router)