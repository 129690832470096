import { Credentials } from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

export const s3Client = new S3({
    region: process.env.REACT_APP_BUCKET_OBJ_REGION,
    endpoint: process.env.REACT_APP_BUCKET_OBJ_URL,
    sslEnabled: true,
    s3ForcePathStyle: false,
    credentials: new Credentials({
      accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY,
    }),
});

export async function uploadFileToObjectStorage(base64Data, path, fileName, fileType, extension) {
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_OBJ_NAME,
      Key: `${fileName}-${path}`,
      Body: base64Data,
      ACL: 'public-read',
      // ContentEncoding: 'base64',
      ContentType: `${fileType}`,
      processData: false,
    };


    console.log(params)
  
    // see: http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
    const { Location } = await s3Client.upload(params).promise();
    return Location;
  }
  
export async function deleteFileFromObjectStorage(url) {

    const Key = url.split(`${process.env.REACT_APP_BUCKET_OBJ_URL}/`)[1];

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_OBJ_NAME,
      Key,
    };
  
    // see: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#deleteObject-property
    // eslint-disable-next-line consistent-return
    return s3Client.deleteObject(params).promise();
}