import { createStore, applyMiddleware } from 'redux'
// import thunkMiddleware from 'redux-thunk'
import reducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import localforage from 'localforage';

const persistConfig = {
  key: 'root',
  storage : localforage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor };