// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* DESKTOP */
@media (min-width : 500px) {

    .pricing-card {
        /* padding : 15px; */
        box-shadow: 5px 5px 10px #888888;
        border-radius: 10px;
        min-height: 520px;
        overflow: hidden;
    }

    .pricing-title {
        text-align: center;
        z-index: 2;
    }

}

/* MOBILE */
@media (max-width : 500px) {


}`, "",{"version":3,"sources":["webpack://./src/pages/styles/base_style.css"],"names":[],"mappings":";AACA,YAAY;AACZ;;IAEI;QACI,oBAAoB;QACpB,gCAAgC;QAChC,mBAAmB;QACnB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;QAClB,UAAU;IACd;;AAEJ;;AAEA,WAAW;AACX;;;AAGA","sourcesContent":["\n/* DESKTOP */\n@media (min-width : 500px) {\n\n    .pricing-card {\n        /* padding : 15px; */\n        box-shadow: 5px 5px 10px #888888;\n        border-radius: 10px;\n        min-height: 520px;\n        overflow: hidden;\n    }\n\n    .pricing-title {\n        text-align: center;\n        z-index: 2;\n    }\n\n}\n\n/* MOBILE */\n@media (max-width : 500px) {\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
