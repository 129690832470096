import React, { Component } from 'react'
import { Col, Row, Card, Space, Input, Divider, Button, Empty, notification, Timeline} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PageLayout from '../page_layout'
import { Parallax } from 'react-scroll-parallax';
import Parse from 'parse';

import HeroImage from '../assets/img/hero_image.png'
import MAP from '../assets/img/purple_map.png'
import PricingPabiliImg from '../assets/img/pricing/shop-header.png'
import PricingDropImg from '../assets/img/pricing/drop-header.png'
import PricingShipImg from '../assets/img/pricing/ship-header.png'

const { Meta } = Card


class Pricing extends Component {
    // constructor(props) {
    //     super(props);
    // }
    
    state = {

    }

    componentDidMount = () => {
        
    }


    render() { 
        let {is_loading, tracking_number, tracking_data, track_events} = this.state
        return (
            <PageLayout
                title={'Pricing - CDLExpress'}
            >
                <Parallax speed={-10}>
                    <Row style={{paddingBottom : 120, marginTop : 100}}>
                        <Col span={24}>
                            <Space direction='vertical' 
                                style={{width : '100%', alignItems : 'center'}} 
                                size={30}
                            >
                                <h3 className='page-title'>Pricing</h3>
                                <div
                                    style={{
                                        // display : 'flex',
                                        // flexDirection : 'row',
                                        minHeight : 500, 
                                        width : 1000
                                    }}
                                >
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <Card
                                                    hoverable
                                                    style={{
                                                        width: 'auto',
                                                        minHeight : 500
                                                    }}
                                                    cover={<img alt="example" src={PricingPabiliImg} />}
                                                >
                                                    <Meta title="Pabili Service" description={
                                                        <small>
                                                        Bridging the gap between you and the Philippines, <br/>
                                                        no matter where you are in the world! <br/>
                                                        Dreaming of unique Filipino treasures or everyday essentials?<br/> 
                                                        Simply place your order, and our expert team will navigate the local markets and retailers on your behalf.
                                                        </small>
                                                    } 
                                                    />
                                                    <Divider />
                                                    <div>
                                                        <b>For as low as</b>
                                                        <h3>
                                                            PHP 100
                                                        </h3><small>per Kilo</small>
                                                    </div>
                                                    
                                                </Card>
                                            </Col>
                                        <Col span={8}>
                                            <Card
                                                hoverable
                                                style={{
                                                    width: 'auto',
                                                    minHeight : 500
                                                }}
                                                cover={<img alt="example" src={PricingDropImg} />}
                                            >
                                                <Meta title="Drop & Storage Service" description={
                                                    <small>
                                                        Our innovative Shopping Hub in the heart of the Philippines, 
                                                        designed to cater to customers worldwide!
                                                        {/* <br/>Simply place your orders online and have them delivered to our centralized hub. From there, our dedicated team will ensure prompt processing and secure storage of your parcels until you're ready to receive them. */}
                                                        <br/>Experience convenience redefined with our Shopping Hub, your gateway to hassle-free international shopping in the Philippines.

                                                    </small>
                                                } />
                                                 <Divider />
                                                    <div>
                                                        <Row>
                                                            <Col span={14} style={{borderRight : `1px solid #888`}}>
                                                                <b>For as low as</b>
                                                                <h3>
                                                                    PHP 50
                                                                </h3><small>per Parcel</small>
                                                            </Col>
                                                            <Col span={10} style={{padding : 10}}>
                                                                <b>Storage fee</b>
                                                                <h5>
                                                                    PHP 250
                                                                </h5>
                                                                <small style={{marginTop : 10, marginLeft : 5}}> per Week</small>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </div>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card
                                                hoverable
                                                style={{
                                                width: 'auto',
                                                minHeight : 500
                                                }}
                                                cover={<img alt="example" src={PricingShipImg} />}
                                            >
                                                <Meta title="Forwarding Service" description={
                                                    <small>
                                                        Your gateway to seamless international shipping from the Philippines to destinations worldwide. <br/>
                                                        Whether you're a global shopper seeking Filipino treasures or a business expanding your reach, we've got you covered. 
                                                        <br/>Simply entrust us with your parcels, and our efficient team will handle the logistics, ensuring safe and timely delivery to your desired destination.
                                                    </small>
                                                }/>
                                                <Divider />
                                                <div>
                                                    <h4>
                                                        Request a quotation
                                                    </h4>
                                                    <small></small>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            
                            </Space>
                        </Col>
                    </Row>
                </Parallax>
            </PageLayout>
        );
    }
}
 
export default Pricing;