import React, { useState, useEffect } from 'react';
import { Col, Row, Divider, Input, Button, Modal, Card } from 'antd';
import _ from 'lodash';
import Parse from 'parse'
import Swal from "sweetalert2";
import moment from 'moment'
import {NUMBER_WITH_COMMAS} from '../../utils/index'
import CryptoJS from "crypto-js";

//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "../../redux/actions";
import { ROLE, USER_PROFILE, ACTIVE_CART, TO_PAY } from '../../redux/actions/ActionTypes'

const { Meta } = Card;
const secretPass = process.env.REACT_APP_PARSE_MASTER_KEY;

function PaymentOptionModal(props){

    let { UserProfile, data, actions } = props
    let { PROFILE } = UserProfile

    let {total_net_amount} = data

    useEffect(() => {

    },[])


    const payUsingCredit = async () => {


        // console.log(data)

        let payload = {
            customer_id : UserProfile.PROFILE.id,
            billing_id : data.id,
            invoice_id : data.incremental_id,
            user_id : data.user.id,
            to_pay : data.total_net_amount,
            timestamp : moment().unix()
        }

        const encryptedString = CryptoJS.AES.encrypt(
            JSON.stringify(payload),
            secretPass
        ).toString();
        
        await actions.setValue({data : encryptedString, type : 'credit'}, TO_PAY)
        
        window.location.href = `/payment/credit?hash=${encryptedString}`
        
    }

    return (

        <Modal
            onCancel={() => props.close()}
            open = {props.visible}
            title={`Choose Payment Method`}
            footer={null}
            width={540}
        >
            <Divider />
            <div style={{height : '300 !important'}}>
                
                <Row>
                    <Col span={12}>
                        <Card
                            hoverable={PROFILE.credit_data.running_credits && PROFILE.credit_data.running_credits >= total_net_amount ? true : false}
                            title={"Credits"}
                            style={{ width: 240, minHeight : 170 }}
                            onClick={() => payUsingCredit()}
                        >
                            <Meta 
                                title={`PHP ${PROFILE.credit_data.running_credits ? NUMBER_WITH_COMMAS(PROFILE.credit_data.running_credits) : '0.00'}`} 
                                description="Available Credits" 
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card
                            hoverable
                            title={"Card / Online Banks"}
                            style={{ width: 240, minHeight : 170 }}
                        >
                            <Meta title="" description="Visa, MasterCard, GCash, Maya, InstaPay etc.." />
                        </Card>
                    </Col>
                </Row>
                
            </div>
        </Modal>

    )

}


const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
    };
  
    return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionModal)