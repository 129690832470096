
import React, { Component } from 'react'
import { Col, Layout, Row, Space, notification } from 'antd';
import Header from './component/header';
import Logo from './assets/img/logo.png'
import MAP from './assets/img/purple_map.png'
import GROCERY_BG from './assets/img/grocery_bg.png'
import Parse from 'parse'
import socket from './utils/socket'


//REDUX
import { connect } from "react-redux"
import {bindActionCreators} from 'redux'
import {SetValue} from "./redux/actions";
import { ROLE, USER_PROFILE } from './redux/actions/ActionTypes'


const {Content, Footer} = Layout

class PageLayout extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        background_image : null
    }

    componentDidMount = async () => {
        let {actions} = this.props

        // console.log('LAYOUT ', this.props)

        if(this.props.children && this.props.children._owner && this.props.children._owner.elementType.name === "Shop"){
            this.setState({background_image : null})
        }else{
            this.setState({background_image : MAP})
        }

        if(await Parse.User.currentAsync()){
            let user = await Parse.User.currentAsync()

            await Promise.all([
                await user.relation('Roles').query().each(async r => {
                    
                    if(user && user.get('ProfileData')){
                        console.log('IS_LOGGED_IN')
                        if(r.get('name') === 'USER' || r.get('name') === 'ADMIN'){
                            await Parse.Cloud.run('get_client_profile', {pdata : user.get('ProfileData').id}).then(async profileData => {
                                await actions.setValue({
                                    ROLE : r.get('name')
                                }, ROLE)

                                await actions.setValue({
                                    PROFILE : profileData.data
                                }, USER_PROFILE)
                            })
                        }
                    }
                })
            ])


            if(socket.connected){
                console.log('SOCKET CONNECTED')
                socket.on("WebClientListener", (data) => {
                    console.log('SOCKET EMITTED ', data)
                    if(data.event_name === "CLIENT_NOTIF_SERVICES" || data.event_name === "CLIENT_NOTIF_SHIPPING" || data.event_name === "CLIENT_NOTIF_REMINDER"){
                        if(data.event_target === user.id){
                            notification['success']({
                                message: data.data.title,
                                description: data.data.message
                            });
                        }
                        
                    }
                })
        
            }
            
        }
    }

    render() { 
        let { background_image } = this.state
        return (
            <Layout style={{ minHeight: '50vh', maxWidth : '100vw', backgroundColor : '#fff'}}>  
                    <Header 
                        title={this.props.title}
                    />
                        <Content>
                        
                            <div className="body-content">
                                <img src={background_image} className="backgroundMap"/>
                                {this.props.children}
                                {/* {React.cloneElement(this.props.children, { loggednn: 'TEST' })} */}
                            </div>
                            
                        </Content>
                    <Footer style={{backgroundColor : '#363636', zIndex : 1}}>
                        <div className='footer-area'>
                            <Row>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <img className='footer-logo' src={Logo}/>
                                    <h3 style={{ color : '#fff', fontWeight : 'bold', marginTop : 25}}> Delivering success.</h3>
                                    <span style={{ color : '#fff'}}>
                                        We take pride in delivering more than just packages. <br/>We deliver growth and satisfaction, by providing efficient and reliable delivery solutions for you.
                                    </span>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{color : '#fff'}}>
                                        
                                </Col>
                                <Col sxs={24} sm={24} md={6} lg={6} xl={6}  style={{color : '#fff', paddingTop : 50}}>
                                    <Space direction='vertical'>
                                        <a>How it Works?</a>
                                        <a>About Us</a>
                                        <a>Privacy Policy</a>
                                        <a>Terms & Conditions</a>
                                    </Space>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6}  style={{color : '#fff', paddingTop : 20}}>
                                    <Space direction='vertical'>
                                        <h6 style={{color : '#F6C444'}}>Contact Us</h6>
                                        <b>30 Tandang Sora, Parang, Marikina City, PH 1809</b>
                                        <b>customer@cdlexpress.ph</b>
                                        <b>(02) 8742-5973</b>
                                        <b>(+63) 945-209-1190</b>
                                    </Space>
                                </Col>
                            </Row>
                            <div style={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent : 'center', marginTop : 100}}>
                                <span style={{color : '#fff'}}>Copyright @ 2023. CDLExpress. All rights reserved.</span>
                            </div>
                        </div>
                    </Footer>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        Role : state.Role,
        UserProfile : state.UserProfile
    }
}

const mapDispatchToProps = dispatch => {
    const actions = {
        setValue: SetValue
      };
  
      return {
        actions: bindActionCreators(actions, dispatch)
    }; 
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout)

// export default PageLayout